import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const getPublicFeatureById = createAsyncThunk('public/feature/id', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.publicFeature}/${data}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getPublicFeatureCalculation = createAsyncThunk(
    'public/featue/estimationCalculation',
    async (id, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.publicEstimationTotal}/${id}`);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'publicLink',
    initialState: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPublicFeatureCalculation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPublicFeatureCalculation.rejected, (state) => {
                state.isLoading = false;
                state.publicEstimationCalc = {};
            })
            .addCase(getPublicFeatureCalculation.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.publicEstimationCalc = payload.data;
            })
            .addCase(getPublicFeatureById.pending, (state) => {
                state.isLoading = true;
                state.publicProposalFeature = {};
            })
            .addCase(getPublicFeatureById.rejected, (state) => {
                state.isLoading = false;
                state.publicProposalFeature = {};
            })
            .addCase(getPublicFeatureById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.publicProposalFeature = payload?.result;
            });
    },
});

export default slice.reducer;
