export const subFieldValueHandler = ({ permisionValues, primaryIndex, subIndex, checked }) => {
    let tempPermisionValues = structuredClone(permisionValues);
    tempPermisionValues[primaryIndex].permissions[subIndex].value = checked;
    if (checked) {
        tempPermisionValues[primaryIndex].value = checked;
        const requiredPids = tempPermisionValues[primaryIndex].permissions[subIndex].requires || [];
        const requiredPidPermissionsIndexes = tempPermisionValues[primaryIndex].permissions.reduce((acc, crr, inx) => {
            const isRequired = (pid) => {
                if (requiredPids.includes(pid)) return true;
                // check one of optional required field is checked
                if (
                    requiredPids.some((reqdPid) => {
                        const optionalPids = reqdPid?.split('|') || [];
                        if (
                            optionalPids?.length &&
                            tempPermisionValues[primaryIndex].permissions?.some(
                                ({ pid: pidValue, value }) => value && optionalPids?.includes(pidValue)
                            )
                        )
                            return false;
                        return optionalPids[0] === pid;
                    })
                )
                    return true;
            };
            if (isRequired(crr.pid)) acc.push(inx);
            return acc;
        }, []);
        tempPermisionValues[primaryIndex].permissions[subIndex].value = checked;
        const optionalPids = tempPermisionValues[primaryIndex].permissions[subIndex].optionalWith || [];
        if (optionalPids?.length) {
            const optionPidIndex = tempPermisionValues[primaryIndex].permissions.findIndex(
                ({ pid }) => optionalPids[0] === pid
            );
            tempPermisionValues[primaryIndex].permissions[optionPidIndex].value = !checked;
        }
        requiredPidPermissionsIndexes.forEach((permissionRequiredIndex) => {
            tempPermisionValues = subFieldValueHandler({
                permisionValues: tempPermisionValues,
                primaryIndex,
                subIndex: permissionRequiredIndex,
                checked,
            });
        });
    } else {
        const { pid } = tempPermisionValues[primaryIndex].permissions[subIndex];
        const dependentPermissionsIndexes = tempPermisionValues[primaryIndex].permissions.reduce((acc, crr, inx) => {
            if (
                crr.value &&
                (crr.requires?.includes(pid) ||
                    crr.requires?.some((reqdPid) => {
                        const optionalPids = reqdPid?.split('|') || [];
                        return optionalPids?.includes(pid);
                    }))
            )
                acc.push(inx);
            return acc;
        }, []);
        if (tempPermisionValues[primaryIndex]?.subModules?.length) {
            tempPermisionValues[primaryIndex]?.subModules?.forEach((val, indx) => {
                const dependentSubPermissionsIndexes = tempPermisionValues[primaryIndex].subModules[
                    indx
                ].permissions.reduce((acc, crr, inx) => {
                    if (
                        crr.value &&
                        (crr.requires?.includes(pid) ||
                            crr.requires?.some((reqdPid) => {
                                const optionalPids = reqdPid?.split('|') || [];
                                return optionalPids?.includes(pid);
                            }))
                    )
                        acc.push(inx);
                    return acc;
                }, []);
                dependentSubPermissionsIndexes.forEach((permissionRequiredIndex) => {
                    tempPermisionValues[primaryIndex].subModules[indx].permissions[permissionRequiredIndex].value =
                        checked;
                });
                const doesValueExist = tempPermisionValues[primaryIndex].subModules[indx].permissions.some(
                    ({ value }) => value
                );
                if (!doesValueExist) {
                    tempPermisionValues[primaryIndex].subModules[indx].value = checked;
                }
            });
        }
        dependentPermissionsIndexes.forEach((permissionRequiredIndex) => {
            tempPermisionValues = subFieldValueHandler({
                permisionValues: tempPermisionValues,
                primaryIndex,
                subIndex: permissionRequiredIndex,
                checked,
            });
        });
        const doesValueExist = tempPermisionValues[primaryIndex].permissions.some(({ value }) => value);
        if (!doesValueExist) {
            tempPermisionValues[primaryIndex].value = checked;
        }
    }
    return tempPermisionValues;
};

const formatLabel = (label) => label?.split('_').join(' ').toLowerCase();
const formatPermission = (permissions) =>
    permissions.map(({ id, permission_label, requires, pid, optionalWith }) => ({
        id,
        label: permission_label,
        value: false,
        requires,
        pid,
        optionalWith,
    }));
// .sort((a) => (a?.label?.includes('List') ? -1 : 1));
export const formatPermissionResponse = (permissionStructure) => {
    const newPermissionStucture = permissionStructure.reduce((accumalator, { module, id, permissions, subModules }) => {
        // if (subModules?.length) {
        //     return [...accumalator, ...formatPermissionResponse(subModules)];
        // }
        if (permissions?.some(({ sub_permissions }) => sub_permissions?.length)) {
            let subPermissions = [];
            permissions.forEach(({ sub_permissions, permission, permission_label, ...rest }) => {
                subPermissions = [
                    ...subPermissions,
                    {
                        ...rest,
                        parentId: id,
                        module: permission,
                        permissions: sub_permissions,
                        subModules: subModules?.length ? [...formatPermissionResponse(subModules)] : [],
                    },
                ];
            });
            return [
                ...accumalator,
                {
                    label: formatLabel(module),
                    id,
                    value: false,
                    subModules: [...formatPermissionResponse(subPermissions)],
                },
            ];
        }
        const currentVal = {
            label: formatLabel(module),
            id,
            value: false,
            permissions: formatPermission(permissions),
            subModules: subModules?.length ? [...formatPermissionResponse(subModules)] : [],
        };
        return [...accumalator, currentVal];
    }, []);
    return newPermissionStucture;
};

const formatEditPermission = (permissions, designationPermissions) =>
    permissions.map(({ id, permission_label, requires, pid, optionalWith }) => ({
        id,
        label: permission_label,
        value: designationPermissions.some(({ permissionId, status }) => id === permissionId && status),
        requires,
        pid,
        optionalWith,
    }));
// .sort((a) => (a?.label?.includes('List') ? -1 : 1));
export const formatEditPermissionResponse = (permissionStructure, designationPermissions) => {
    const newPermissionStucture = permissionStructure.reduce(
        (accumalator, { module, id, permissions = [], subModules = [], parentsId }) => {
            // if (subModules?.length) {
            //     return [...accumalator, ...formatEditPermissionResponse(subModules, designationPermissions)];
            // }
            if (permissions?.some(({ sub_permissions }) => sub_permissions?.length)) {
                let subPermissions = [];
                permissions.forEach(({ sub_permissions, permission, permission_label, ...rest }) => {
                    subPermissions = [
                        ...subPermissions,
                        {
                            ...rest,
                            parentsId: id,
                            module: permission,
                            permissions: sub_permissions,
                            subModules: subModules?.length
                                ? [...formatEditPermissionResponse(subModules, designationPermissions)]
                                : [],
                        },
                    ];
                });
                const subModulesValue = subPermissions?.length
                    ? [...formatEditPermissionResponse(subPermissions, designationPermissions)]
                    : [];
                return [
                    ...accumalator,
                    {
                        label: formatLabel(module),
                        id,
                        value:
                            permissions.some(({ id: subId }) =>
                                designationPermissions.some(
                                    ({ permissionId, status }) => permissionId === subId && status
                                )
                            ) || subModulesValue?.some(({ value }) => value),
                        subModules: subModulesValue,
                    },
                ];
            }

            const subModulesValue = subModules?.length
                ? [...formatEditPermissionResponse(subModules, designationPermissions)]
                : [];
            const currentVal = {
                label: formatLabel(module),
                id,
                parentsId,
                value:
                    permissions.some(({ id: subId }) =>
                        designationPermissions.some(({ permissionId, status }) => permissionId === subId && status)
                    ) || subModulesValue?.some(({ value }) => value),
                permissions: formatEditPermission(permissions, designationPermissions),
                subModules: subModulesValue,
            };
            return [...accumalator, currentVal];
        },
        []
    );
    return newPermissionStucture;
};

export const getDefaultRoute = (userPermissions) => {
    const hasVisibleSubModules = userPermissions[0]?.subModules.some((subModule) => subModule.webVisibility !== false);

    if (hasVisibleSubModules) {
        return `/${
            (userPermissions[0]?.subModules[0]?.permissions[0]?.sub_permissions.length &&
                `${userPermissions[0]?.module_label
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('_', '-')}/${userPermissions[0]?.subModules[0]?.module_label
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('_', '-')}/${userPermissions[0]?.subModules[0]?.permissions[0]?.permission_label
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('_', '-')}`) ||
            (userPermissions[0]?.subModules.length &&
                `${userPermissions[0]?.module_label
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('_', '-')}/${userPermissions[0]?.subModules[0]?.module_label
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('_', '-')}`) ||
            userPermissions[0]?.module_label.toLowerCase().replace(' ', '-').replace('_', '-') ||
            'dashboard'
        }`;
    }

    return `/${userPermissions[0]?.module_label.toLowerCase().replace(' ', '-').replace('_', '-')}`;
};
