import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const getUserPermisions = createAsyncThunk('fetchUserPermisions', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(serviceEndpoints.userPermission);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

const slice = createSlice({
    name: 'permissions',
    initialState: {
        userPermissions: [],
        isLoading: true,
        designation: '',
    },

    extraReducers: (builder) => {
        builder

            .addCase(getUserPermisions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserPermisions.rejected, (state) => {
                state.isLoading = false;
                state.userPermissions = [];
                state.designation = '';
            })
            .addCase(getUserPermisions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.userPermissions = payload?.permissions?.userPermission || [];
                state.designation = payload?.permissions?.designation || '';
            });
    },
});

export default slice.reducer;
