import React, { useState, useMemo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './Header';
import Sidenav from './Sidenav';
import { darkModeContext } from '../../components/context';

const queryClient = new QueryClient();

function PrivateLayout({ children }) {
    const [collapse, setCollapse] = useState(sessionStorage.getItem('sidebarCollapsed') === 'true');
    const [darkStatus, setDarkStatus] = useState(sessionStorage.getItem('darkMode') === 'true');

    const valueProvider = useMemo(() => ({ darkStatus }), [darkStatus]);
    const { isLoading, userPermissions } = useSelector((state) => state.permissionReducer);
    if (isLoading && !userPermissions?.length) return null;

    return (
        <QueryClientProvider client={queryClient}>
            <div className="wrapper-main">
                <Sidenav collapse={collapse} setCollapse={setCollapse} />
                <div className={`main ${collapse && 'main--slide'}`}>
                    <Header
                        collapse={collapse}
                        setCollapse={setCollapse}
                        getDarkMode={(darkMode) => setDarkStatus(darkMode)}
                    />
                    <Suspense fallback={<div />}>
                        <darkModeContext.Provider value={valueProvider}>{children}</darkModeContext.Provider>
                    </Suspense>
                </div>
            </div>
            <div className="copy-right">
                <p className="highlight">
                    Copyright {new Date().getFullYear()} <span> &copy; Spericorn</span>
                </p>
            </div>
        </QueryClientProvider>
    );
}

export default PrivateLayout;
