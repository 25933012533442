import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

// Create a new WhitelistedIP
export const postWhitelistedIP = createAsyncThunk('add-whitelistedIP', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.post(serviceEndpoints.whiteListIP, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All WhitelistedIP
export const getWhitelistedIP = createAsyncThunk('list-WhitelistedIP', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.whiteListIP}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Update WhiteListIP
export const updateWhiteListIP = createAsyncThunk('update-whiteListIP', async (data, { rejectWithValue }) => {
    const body = {
        ip: data.ip,
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.whiteListIP}/${data.id}`, body);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete WhiteListIP
export const deleteWhiteListIP = createAsyncThunk('delete-whiteListIP', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.whiteListIP}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'whitelistedIP',
    initialState: {
        errorMessage: '',
        successMessage: '',
        WhitelistedIPData: [],
        isLoading: false,
        isDeletionLoading: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getWhitelistedIP.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
                state.WhitelistedIPData = [];
            })
            .addCase(getWhitelistedIP.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.WhitelistedIPData = [];
            })
            .addCase(getWhitelistedIP.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.WhitelistedIPData = payload || null;
            })
            .addCase(postWhitelistedIP.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postWhitelistedIP.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postWhitelistedIP.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase(updateWhiteListIP.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateWhiteListIP.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(updateWhiteListIP.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(deleteWhiteListIP.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteWhiteListIP.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteWhiteListIP.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
            })

            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
