import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getTechnologyList = createAsyncThunk('tech-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.technology}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const addTechnology = createAsyncThunk('add-tech', async (data, { rejectWithValue }) => {
    const body = data;

    try {
        const response = await proposalGateway.post(serviceEndpoints.technology, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const editTechnology = createAsyncThunk('edit-tech', async ({ id, data }, { rejectWithValue }) => {
    const body = data;
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.technology}/${id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteTechnology = createAsyncThunk('delete-tech', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.technology}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
const slice = createSlice({
    name: 'tech',
    initialState: {
        techList: {},
        isLoading: false,
        isDeletionLoading: false,
        errorMessage: '',
        successMessage: '',
        isDownloading: '',
        downloadmsg: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(addTechnology.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(addTechnology.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(addTechnology.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
            })
            .addCase(editTechnology.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(editTechnology.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(editTechnology.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
            })
            .addCase(getTechnologyList.pending, (state) => {
                state.isLoading = true;
                state.techList = {};
            })
            .addCase(getTechnologyList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.techList = {};
            })
            .addCase(getTechnologyList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.techList = payload?.data || {};
            })

            .addCase(deleteTechnology.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteTechnology.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteTechnology.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
