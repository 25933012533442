import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryArrParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getProjectList = createAsyncThunk('project-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.projects}${getQueryArrParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const addProject = createAsyncThunk('add-project', async (data, { rejectWithValue, dispatch }) => {
    const body = data;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.post(serviceEndpoints.projects, body, { headers });
        const { success, message } = response.data;
        if (success) {
            dispatch(
                getProjectList({
                    currentPage: 1,
                    limit: 10,
                })
            );
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const editProject = createAsyncThunk('edit-project', async ({ id, data }, { rejectWithValue }) => {
    const body = data;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.projects}/${id}`, body, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteProject = createAsyncThunk('delete-project', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.projects}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getProjectId = createAsyncThunk('id-project', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.projects}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Generate Pdf
export const generatePdfProject = createAsyncThunk('pdf-generate', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.projects}/${id}/generate-pdf`, {
            timeout: 60000,
        });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const getDomainProjectList = createAsyncThunk('domain-project-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.projects}/domain/${data.domainId}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'domains',
    initialState: {
        projectList: {},
        domainProjectList: [],
        isLoading: false,
        isDeletionLoading: false,
        isDeleted: false,
        isEdited: false,
        errorMessage: '',
        successMessage: '',
        isDownloading: '',
        downloadmsg: '',
        projectDetails: {},
    },

    extraReducers: (builder) => {
        builder
            .addCase(addProject.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(addProject.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(addProject.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
            })

            .addCase(editProject.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isEdited = false;
            })
            .addCase(editProject.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
                state.isEdited = false;
            })
            .addCase(editProject.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
                state.isEdited = true;
            })
            .addCase(getProjectList.pending, (state) => {
                state.isLoading = true;
                state.projectList = {};
            })
            .addCase(getProjectList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.projectList = {};
            })
            .addCase(getProjectList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.projectList = payload.projects || {};
            })

            .addCase(deleteProject.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isDeleted = false;
            })
            .addCase(deleteProject.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
                state.isDeleted = true;
            })
            .addCase(deleteProject.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
                state.isDeleted = false;
            })
            .addCase(getProjectId.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.projectDetails = {};
            })
            .addCase(getProjectId.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = '';
                state.projectDetails = payload;
            })
            .addCase(getProjectId.rejected, (state) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = '';
                state.projectDetails = {};
            })

            .addCase(generatePdfProject.pending, (state) => {
                state.isDownloading = true;
            })
            .addCase(generatePdfProject.fulfilled, (state) => {
                state.isDownloading = false;
            })
            .addCase(generatePdfProject.rejected, (state) => {
                state.isDownloading = false;
            })
            .addCase(getDomainProjectList.pending, (state) => {
                state.isLoading = true;
                state.domainProjectList = [];
            })
            .addCase(getDomainProjectList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.domainProjectList = [];
            })
            .addCase(getDomainProjectList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.domainProjectList = payload?.projects || [];
            })

            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
                state.fileuploadstatus = '';
            });
    },
});

export default slice.reducer;
