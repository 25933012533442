import { createAsyncThunk, createSlice, createAction, current } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const clearAllGetProposal = createAction('clearAllGetProposal');

export const changeProposalSearch = createAction('changeProposalSearch', (search) => ({
    payload: { search },
}));

export const getProposal = createAsyncThunk(
    'proposal/list',
    async ({ disableloader, ...param }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.proposal}?${getQueryParams(param)}`);
            const { success, message } = response.data;
            if (success) {
                return { ...response?.data, disableloader };
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const getProposalFeature = createAsyncThunk('proposal/list/id', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.proposalFeature}/${data}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getFeatureEstimations = createAsyncThunk(
    'proposal/feature/estimations',
    async (data, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.proposalEstimation}/${data}`);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const getEstimationPermission = createAsyncThunk(
    'proposal/feature/estimations/permission',
    async (data, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(
                `${serviceEndpoints.proposal}/${data}/estimation/technology-stacks`
            );
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const getProposalById = createAsyncThunk('proposal/dataById', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.proposal}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getActivityLog = createAsyncThunk('proposal/activityLog', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.activityLog}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getProposalInfo = createAsyncThunk('proposal/info', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.proposalInfo}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const downloadProposal = createAsyncThunk(
    '/proposal-download',
    async ({ id, projectName, ...params }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(
                `${serviceEndpoints.proposalDownload}/${id}?${getQueryParams(params)}`,
                {
                    responseType: 'blob',

                    timeout: 160000,
                }
            );

            let contentType = response.headers['content-type'];
            if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                contentType = 'application/xlsx';
            } else if (contentType === 'application/msword') {
                contentType = 'application/doc';
            } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                contentType = 'application/docx';
            } else if (contentType === 'application/vnd.ms-excel') {
                contentType = 'application/xls';
            }
            const fileExtension = contentType.split('/')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectName || 'document'}.${fileExtension}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const downloadProposalFrd = createAsyncThunk(
    '/proposal-frd-download',
    async ({ id, projectName, ...params }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(
                `${serviceEndpoints.proposal}/download-frd/${id}?${getQueryParams(params)}`,
                {
                    responseType: 'blob',
                    timeout: 160000,
                }
            );
            let contentType = response.headers['content-type'];
            if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                contentType = 'application/xlsx';
            } else if (contentType === 'application/msword') {
                contentType = 'application/doc';
            } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                contentType = 'application/docx';
            } else if (contentType === 'application/vnd.ms-excel') {
                contentType = 'application/xls';
            }
            const fileExtension = contentType.split('/')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectName || 'document'}.${fileExtension}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create shareProposal
export const createShareProposal = createAsyncThunk('add-shareProposal', async ({ data, id }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(
            `${serviceEndpoints.proposal}/${id}${serviceEndpoints.sendMail}`,
            data
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const fileExportProposal = createAsyncThunk(
    'export-proposal',
    async ({ disableloader, ...param }, { rejectWithValue }) => {
        const headers = {
            'x-timezone-offset': -new Date().getTimezoneOffset(),
            'x-response-format': 'excel',
        };
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.proposal}?${getQueryParams(param)}`, {
                headers,
            });
            if (response.status < 200 || response.status >= 300) {
                return rejectWithValue(response.data);
            }
            return response.data;
        } catch (error) {
            if ((error.response && error.response.status === 401) || error.response.status === 400) {
                return rejectWithValue(error.response.data);
            }
        }
    }
);

export const fileExportActivityLog = createAsyncThunk('export-activity', async (data, { rejectWithValue }) => {
    const headers = {
        'x-timezone-offset': -new Date().getTimezoneOffset(),
        'x-response-format': 'excel',
    };
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.activityLog}?${getQueryParams(data)}`, {
            headers,
        });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const resetMsg = createAction('resetMsg');

const slice = createSlice({
    name: 'getproposal',
    initialState: {
        estimationLoader: false,
        isLoading: false,
        activityLoader: false,
        infoLoader: false,
        proposalData: {},
        proposalDetails: {},
        proposalFeature: {},
        featurePermission: {},
        featureEstimations: [],
        activityDetails: {},
        proposalInfo: {},
        searchValue: '',
        qaPercentage: 0,
        isDownloading: false,
        isProposalExcelDownloading: false,
        exportData: null,
        isActivityDownloading: false,
        exportActivityData: null,
        errorMessage: '',
        successMessage: '',
        proposalError: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(getProposal.pending, (state, { meta }) => {
                state.isLoading = !meta?.arg?.disableloader;
                state.proposalData = meta?.arg?.disableloader ? current(state)?.proposalData : {};
            })
            .addCase(getProposal.rejected, (state) => {
                state.isLoading = false;
                state.proposalData = {};
            })
            .addCase(getProposal.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.proposalData = payload;
            })

            .addCase(getEstimationPermission.pending, (state) => {
                state.featurePermission = {};
            })
            .addCase(getEstimationPermission.rejected, (state) => {
                state.featurePermission = {};
            })
            .addCase(getEstimationPermission.fulfilled, (state, { payload }) => {
                state.featurePermission = payload;
            })
            .addCase(getProposalById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProposalById.rejected, (state) => {
                state.isLoading = false;
                state.proposalDetails = {};
            })
            .addCase(getProposalById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.proposalDetails = payload;
            })

            .addCase(getProposalInfo.pending, (state) => {
                state.infoLoader = true;
                state.errorMessage = '';
            })
            .addCase(getProposalInfo.rejected, (state, { payload }) => {
                state.infoLoader = false;
                state.proposalInfo = {};
                state.proposalError = payload;
            })
            .addCase(getProposalInfo.fulfilled, (state, { payload }) => {
                state.infoLoader = false;
                state.proposalInfo = payload;
                state.errorMessage = '';
            })

            .addCase(getActivityLog.pending, (state) => {
                state.activityLoader = true;
                state.activityDetails = {};
            })
            .addCase(getActivityLog.rejected, (state) => {
                state.activityLoader = false;
                state.activityDetails = {};
            })
            .addCase(getActivityLog.fulfilled, (state, { payload }) => {
                state.activityLoader = false;
                state.activityDetails = payload;
            })

            .addCase(getProposalFeature.pending, (state) => {
                state.estimationLoader = true;
                state.isLoading = true;
                state.proposalFeature = {};
            })
            .addCase(getProposalFeature.rejected, (state) => {
                state.estimationLoader = false;
                state.isLoading = false;
                state.proposalFeature = {};
            })
            .addCase(getProposalFeature.fulfilled, (state, { payload }) => {
                state.estimationLoader = false;
                state.isLoading = false;
                state.proposalFeature = payload?.result;
            })
            .addCase(getFeatureEstimations.pending, (state) => {
                state.isLoading = true;
                state.featureEstimations = [];
                state.qaPercentage = 0;
            })
            .addCase(getFeatureEstimations.rejected, (state) => {
                state.isLoading = false;
                state.featureEstimations = [];
                state.qaPercentage = 0;
            })
            .addCase(getFeatureEstimations.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.featureEstimations = payload?.result?.estimations;
                state.qaPercentage = payload?.result?.proposal?.qaPercentage;
            })
            .addCase(downloadProposal.pending, (state) => {
                state.isDownloading = true;
            })
            .addCase(downloadProposal.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isDownloading = false;
                state.errorMessage = message;
            })
            .addCase(downloadProposal.fulfilled, (state) => {
                state.isDownloading = false;
            })
            .addCase(downloadProposalFrd.pending, (state) => {
                state.isDownloading = true;
            })
            .addCase(downloadProposalFrd.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isDownloading = false;
                state.errorMessage = message;
            })
            .addCase(downloadProposalFrd.fulfilled, (state) => {
                state.isDownloading = false;
            })
            .addCase(createShareProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createShareProposal.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(createShareProposal.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase('changeProposalSearch', (state, { payload }) => {
                state.searchValue = payload?.search || '';
            })
            .addCase('clearAllGetProposal', (state) => {
                state.isLoading = false;
                state.featureEstimations = [];
                state.proposalFeature = {};
            })
            .addCase(fileExportProposal.pending, (state) => {
                state.isProposalExcelDownloading = true;
            })
            .addCase(fileExportProposal.fulfilled, (state, { payload }) => {
                if (payload?.list?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.list?.url;
                    link.download = payload?.list?.filename;
                    link.click();
                }
                state.isProposalExcelDownloading = false;

                state.exportData = payload;
            })
            .addCase(fileExportProposal.rejected, (state) => {
                state.isProposalExcelDownloading = false;
            })
            .addCase(fileExportActivityLog.pending, (state) => {
                state.isActivityDownloading = true;
            })
            .addCase(fileExportActivityLog.fulfilled, (state, { payload }) => {
                if (payload?.data?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.data?.url;
                    link.download = payload?.data?.filename;
                    link.click();
                }
                state.isActivityDownloading = false;

                state.exportActivityData = payload;
            })
            .addCase(fileExportActivityLog.rejected, (state) => {
                state.isActivityDownloading = false;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
