import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getCategoryList = createAsyncThunk('category-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.techcategory}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const addCategory = createAsyncThunk('add-category', async (data, { rejectWithValue }) => {
    const body = data;

    try {
        const response = await proposalGateway.post(serviceEndpoints.techcategory, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const editCategory = createAsyncThunk('edit-category', async ({ id, data }, { rejectWithValue }) => {
    const body = data;
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.techcategory}/${id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteCategory = createAsyncThunk('delete-category', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.techcategory}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
const slice = createSlice({
    name: 'category',
    initialState: {
        categoryList: {},
        isTechLoading: false,
        isDeletionLoading: false,
        errorMessage: '',
        successMessage: '',
        isDownloading: '',
        downloadmsg: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(addCategory.pending, (state) => {
                state.isTechLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(addCategory.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isTechLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(addCategory.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isTechLoading = false;
                state.successMessage = message;
            })
            .addCase(editCategory.pending, (state) => {
                state.isTechLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(editCategory.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isTechLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(editCategory.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isTechLoading = false;
                state.successMessage = message;
            })
            .addCase(getCategoryList.pending, (state) => {
                state.isTechLoading = true;
                state.categoryList = {};
            })
            .addCase(getCategoryList.rejected, (state, { payload }) => {
                state.isTechLoading = false;
                state.errorMessage = payload.message;
                state.categoryList = {};
            })
            .addCase(getCategoryList.fulfilled, (state, { payload }) => {
                state.isTechLoading = false;
                state.categoryList = payload?.data || {};
            })

            .addCase(deleteCategory.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteCategory.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteCategory.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
