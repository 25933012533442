import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const resetMsg = createAction('resetMsg');

export const changePassword = createAsyncThunk('api/user/password', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(serviceEndpoints.passChange, data);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const passwordChange = createSlice({
    name: 'changepswd',
    initialState: {
        resMsg: '',
        successMessage: '',
        errorMessage: '',
        loading: false,
        passwdUpdate: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.passwdUpdate = false;
            })
            .addCase(changePassword.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
                state.passwdUpdate = payload.success || false;
            })
            .addCase(changePassword.rejected, (state, { payload }) => {
                state.loading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.passwdUpdate = false;
            })
            .addCase('resetMsg', (state) => {
                state.successMessage = '';
                state.errorMessage = '';
            });
    },
});

export default passwordChange.reducer;
