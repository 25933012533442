import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

// Create a new user
export const postEmployee = createAsyncThunk('add-employee', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const body = { ...data };
    try {
        const response = await proposalGateway.post(serviceEndpoints.employee, body, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All employees
export const getEmployeeDetails = createAsyncThunk('list-employee', async (data, { rejectWithValue }) => {
    try {
        const { format = 'min', ...params } = data;
        const response = await proposalGateway.get(
            `${serviceEndpoints.employeeList}/${format}?${getQueryParams(params)}`
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Update a new user
export const updateEmployee = createAsyncThunk('update-employee', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.employee}/${data.id}`, data, { headers });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Get employee by id
export const getEmployeeById = createAsyncThunk('get-employee-By-Id', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.employee}/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete Employee
export const deleteEmployee = createAsyncThunk('delete-employee', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.employee}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Deactivate Employee
export const deactivateEmployee = createAsyncThunk('deactivate-employee', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(
            `${serviceEndpoints.employee}/${id}/${serviceEndpoints.employeeDeactivate}`
        );
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Activate Employee
export const activateEmployee = createAsyncThunk('activate-employee', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(
            `${serviceEndpoints.employee}/${id}/${serviceEndpoints.employeeActivate}`
        );
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
// Change Password
export const changePassword = createAsyncThunk('employee-password', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.employeeChangePassword}/${data.id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const fileExportEmployee = createAsyncThunk('export-employee', async (data, { rejectWithValue }) => {
    const headers = {
        'x-timezone-offset': -new Date().getTimezoneOffset(),
        'x-response-format': 'excel',
    };
    try {
        const { format = 'min', ...params } = data;
        const response = await proposalGateway.get(
            `${serviceEndpoints.employeeList}/${format}?${getQueryParams(params)}`,
            { headers }
        );
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Get employee login history  by id
export const getEmployeeLoginHistory = createAsyncThunk(
    'get-employee-history-By-Id',
    async (id, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.employee}/history/${id}`);
            if (response.status < 200 || response.status >= 300) {
                return rejectWithValue(response.data);
            }
            return response.data;
        } catch (error) {
            if ((error.response && error.response.status === 401) || error.response.status === 400) {
                return rejectWithValue(error.response.data);
            }
        }
    }
);

const slice = createSlice({
    name: 'employee',
    initialState: {
        errorMessage: '',
        successMessage: '',
        employeeData: [],
        employeeList: [],
        singleEmployeeData: {},
        passwdUpdate: false,
        isEmpLoading: false,
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
        isEmployeeDownloading: false,
        exportData: null,
        LoginHistoryData: [],
    },

    extraReducers: (builder) => {
        builder
            .addCase(getEmployeeDetails.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
                state.employeeData = [];
            })
            .addCase(getEmployeeDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.employeeData = [];
            })
            .addCase(getEmployeeDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.employeeData = payload || null;
            })
            .addCase(postEmployee.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postEmployee.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postEmployee.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase(updateEmployee.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateEmployee.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(updateEmployee.rejected, (state, { payload }) => {
                state.singleEmployeeData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(deleteEmployee.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteEmployee.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteEmployee.rejected, (state, { payload }) => {
                state.singleEmployeeData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(deactivateEmployee.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deactivateEmployee.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deactivateEmployee.rejected, (state, { payload }) => {
                state.singleEmployeeData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(getEmployeeById.pending, (state) => {
                state.isEmpLoading = true;
                state.errorMessage = '';
                state.entityParams = '';
            })
            .addCase(getEmployeeById.fulfilled, (state, { payload }) => {
                state.isEmpLoading = false;
                state.singleEmployeeData = payload;
            })
            .addCase(getEmployeeById.rejected, (state, { payload }) => {
                state.singleEmployeeData = {};
                state.isEmpLoading = false;
            })
            .addCase(changePassword.pending, (state) => {
                state.isLoading = true;
                state.successMessage = '';
                state.passwdUpdate = false;
            })
            .addCase(changePassword.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
                state.passwdUpdate = payload.success || false;
            })
            .addCase(changePassword.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
                state.passwdUpdate = false;
            })
            .addCase(activateEmployee.pending, (state) => {
                state.isLoading = true;
                state.successMessage = '';
            })
            .addCase(activateEmployee.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(activateEmployee.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(fileExportEmployee.pending, (state) => {
                state.isEmployeeDownloading = true;
            })
            .addCase(fileExportEmployee.fulfilled, (state, { payload }) => {
                if (payload?.data?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.data?.url;
                    link.download = payload?.data?.filename;
                    link.click();
                    state.isEmployeeDownloading = false;
                }

                state.exportData = payload;
            })
            .addCase(fileExportEmployee.rejected, (state) => {
                state.isEmployeeDownloading = false;
            })
            .addCase(getEmployeeLoginHistory.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.entityParams = '';
            })
            .addCase(getEmployeeLoginHistory.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.LoginHistoryData = payload;
            })
            .addCase(getEmployeeLoginHistory.rejected, (state, { payload }) => {
                state.LoginHistoryData = {};
                state.isLoading = false;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
