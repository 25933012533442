import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getDomainList = createAsyncThunk('domain-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.domains}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const addDomain = createAsyncThunk('add-domain', async (data, { rejectWithValue, dispatch }) => {
    const body = data;

    try {
        const response = await proposalGateway.post(serviceEndpoints.domains, body);
        const { success, message } = response.data;
        if (success) {
            dispatch(
                getDomainList({
                    currentPage: 1,
                    limit: 10,
                })
            );
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const editDomain = createAsyncThunk('edit-domain', async ({ id, data, page }, { rejectWithValue, dispatch }) => {
    const body = data;
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.domains}/${id}`, body);
        const { success, message } = response.data;
        if (success) {
            dispatch(
                getDomainList({
                    page,
                    limit: 10,
                })
            );
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteDomain = createAsyncThunk('delete-domain', async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.domains}/${id}`);
        const { success, message } = response.data;
        if (success) {
            dispatch(
                getDomainList({
                    page,
                    limit: 10,
                })
            );
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
const slice = createSlice({
    name: 'domains',
    initialState: {
        domainList: {},
        isLoading: false,
        isDeletionLoading: false,
        errorMessage: '',
        successMessage: '',
        isDownloading: '',
        downloadmsg: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(addDomain.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(addDomain.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(addDomain.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
            })
            .addCase(editDomain.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(editDomain.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.errorMessage = message;
                state.successMessage = '';
            })
            .addCase(editDomain.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.successMessage = message;
            })
            .addCase(getDomainList.pending, (state) => {
                state.isLoading = true;
                state.domainList = {};
            })
            .addCase(getDomainList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.domainList = {};
            })
            .addCase(getDomainList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.domainList = payload.domains || {};
            })

            .addCase(deleteDomain.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteDomain.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteDomain.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
