import { combineReducers } from '@reduxjs/toolkit';
import signInReducer from '../components/auth/signIn/redux';
import forgetReducer from '../components/auth/forgetPassword/redux';
import resetReducer from '../components/auth/resetPassword/redux';
import documentReducer from '../components/documents/redux';
import employeeReducer from '../components/employeesManagement/redux';
import profileReducer from '../components/profile/redux';
import userPasswordChange from '../components/changePassword/redux';
import permissionReducer from '../components/permissionManagement/redux/permissionSlice';
import createProposalReducer from '../components/proposalManagement/redux/createProposal';
import getProposalReducer from '../components/proposalManagement/redux/getProposal';
import dashboardReducer from '../components/dashboard/redux';
import proposalFeatureReducer from '../components/proposalManagement/redux/proposalFeatureSlice';
import publicLink from '../components/proposalManagement/redux/publicLink';
import conversionReducers from '../components/conversions/redux';
import messageReducers from '../components/portfolio/messages/redux';
import whiteListReducers from '../components/portfolio/redux/whiteList';
import domainReducer from '../components/portfolio/redux/domains';
import smtpReducer from '../components/portfolio/redux/smpt';
import upWorkReducer from '../components/portfolio/redux/upWorkSettings';
import portfolioReducer from '../components/portfolio/portfolios/redux/portfolioSlice';
import projectReducer from '../components/portfolio/redux/projects';
import templateReducer from '../components/portfolio/redux/template';
import prospectTemplate from '../components/portfolio/redux/portfolioTemplate';
import techCategoryReducer from '../components/portfolio/redux/technologyCategory';
import technologyReducer from '../components/portfolio/redux/technology';
import otpReducer from '../components/auth/otp/redux/index';
import clientReducer from '../components/clientManagement/redux';
import suggestionReducer from '../components/suggestions/redux';
import imageSettingReducer from '../components/portfolio/redux/imageSettings';
import proposalIntegrationReducer from '../components/proposalManagement/redux/integrations';

const rootReducer = combineReducers({
    signInReducer,
    forgetReducer,
    resetReducer,
    documentReducer,
    employeeReducer,
    profileReducer,
    userPasswordChange,
    permissionReducer,
    createProposalReducer,
    getProposalReducer,
    dashboardReducer,
    proposalFeatureReducer,
    conversionReducers,
    messageReducers,
    whiteListReducers,
    domainReducer,
    smtpReducer,
    portfolioReducer,
    projectReducer,
    templateReducer,
    techCategoryReducer,
    technologyReducer,
    otpReducer,
    clientReducer,
    suggestionReducer,
    imageSettingReducer,
    publicLink,
    prospectTemplate,
    proposalIntegrationReducer,
    upWorkReducer,
});

export default rootReducer;
