import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const clearAllConversions = createAction('clearAllConversions');

export const getConversions = createAsyncThunk('conversion/list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.conversions}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getConversionsById = createAsyncThunk('conversion/dataById', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.conversions}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const updateConversionStatus = createAsyncThunk(
    'conversion/statusChange',
    async ({ id, value }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.put(`${serviceEndpoints.conversionsStatus}/${id}`, {
                status: value,
            });
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const fileExportConversion = createAsyncThunk('export-conversions', async (data, { rejectWithValue }) => {
    const headers = {
        'x-timezone-offset': -new Date().getTimezoneOffset(),
        'x-response-format': 'excel',
    };
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.conversions}?${getQueryParams(data)}`, {
            headers,
        });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'conversations',
    initialState: {
        isLoading: true,
        isEditLoading: true,
        conversionData: {},
        conversionDetails: {},
        successMessage: '',
        errorMessage: '',
        isConversionDownloading: false,
        exportData: null,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getConversions.pending, (state) => {
                state.isLoading = true;
                state.conversionData = {};
            })
            .addCase(getConversions.rejected, (state) => {
                state.isLoading = false;
                state.conversionData = {};
            })
            .addCase(getConversions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.conversionData = payload;
            })

            .addCase(getConversionsById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getConversionsById.rejected, (state) => {
                state.isLoading = false;
                state.conversionDetails = {};
            })
            .addCase(getConversionsById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.conversionDetails = payload;
            })

            .addCase(updateConversionStatus.pending, (state) => {
                state.isEditLoading = true;
                state.successMessage = '';
                state.errorMessage = '';
            })
            .addCase(updateConversionStatus.rejected, (state) => {
                state.isEditLoading = false;
            })
            .addCase(updateConversionStatus.fulfilled, (state, { payload }) => {
                state.isEditLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(fileExportConversion.pending, (state) => {
                state.isConversionDownloading = true;
            })
            .addCase(fileExportConversion.fulfilled, (state, { payload }) => {
                if (payload?.data?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.data?.url;
                    link.download = payload?.data?.filename;
                    link.click();
                }
                state.isConversionDownloading = false;

                state.exportData = payload;
            })
            .addCase(fileExportConversion.rejected, (state) => {
                state.isConversionDownloading = false;
            })
            .addCase('clearAllConversions', (state) => {
                state.isLoading = false;
                state.isEditLoading = false;
                state.conversionDetails = {};
                state.successMessage = '';
                state.errorMessage = '';
            });
    },
});

export default slice.reducer;
