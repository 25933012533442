import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../../config/service';
import serviceEndpoints from '../../../../config/serviceEndpoints';
import { getQueryParams } from '../../../../utils';

export const resetMsg = createAction('resetMsg');

// List All messages
export const getMessageDetails = createAsyncThunk('list-message', async (data, { rejectWithValue }) => {
    try {
        const { ...params } = data;
        const response = await proposalGateway.get(`${serviceEndpoints.message}?${getQueryParams(params)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Get message by id
export const getMessageById = createAsyncThunk('get-message-By-Id', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.message}/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete Message
export const deleteMessage = createAsyncThunk('delete-message', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.message}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const postMessage = createAsyncThunk('post-message', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(serviceEndpoints.message, data);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'message',
    initialState: {
        errorMessage: '',
        successMessage: '',
        MessageData: [],
        singleMessageData: {},
        isMsgLoading: false,
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getMessageDetails.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
                state.MessageData = [];
            })
            .addCase(getMessageDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.MessageData = [];
            })
            .addCase(getMessageDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.MessageData = payload || null;
            })

            .addCase(deleteMessage.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteMessage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteMessage.rejected, (state, { payload }) => {
                state.singleMessageData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(getMessageById.pending, (state) => {
                state.isMsgLoading = true;
                state.errorMessage = '';
                state.entityParams = '';
            })
            .addCase(getMessageById.fulfilled, (state, { payload }) => {
                state.isMsgLoading = false;
                state.singleMessageData = payload;
            })
            .addCase(getMessageById.rejected, (state, { payload }) => {
                state.singleMessageData = {};
                state.isMsgLoading = false;
            })
            .addCase(postMessage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postMessage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })
            .addCase(postMessage.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
                state.isMsgLoading = false;
                state.singleMessageData = {};
            });
    },
});

export default slice.reducer;
