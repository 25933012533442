import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import RoutesIndex from './routes/Index';

function App() {
    return (
        <>
            <ToastContainer closeButton transition={Zoom} icon theme="dark" hideProgressBar />
            <Suspense>
                <Router>
                    <RoutesIndex />
                </Router>
            </Suspense>
        </>
    );
}

export default App;
