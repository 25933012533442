import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { formatRequestBody, formatSubFatureParams } from '../proposalUtils';
import { Notifications } from '../../../utils';

export const clearAllFeature = createAction('clearAllFeature');

export const postModule = createAsyncThunk('proposal/module/create', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(serviceEndpoints.proposalModule, data);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const moveModule = createAsyncThunk('proposal/module/move', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.proposalModule}/move`, data);
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const postFeature = createAsyncThunk('proposal/feature/create', async (data, { rejectWithValue }) => {
    try {
        const body = formatRequestBody(data);
        const response = await proposalGateway.post(serviceEndpoints.proposalFeature, body);
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const editProposalFeature = createAsyncThunk('proposal/feature/edit', async (data, { rejectWithValue }) => {
    try {
        const { title, description, proposalFeatureEstimation, subFeatures } = formatRequestBody(data);
        const response = await proposalGateway.put(`${serviceEndpoints.proposalFeature}/${data.id}`, {
            title,
            description,
            proposalFeatureEstimation,
            subFeatures,
        });
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const editFeatureInline = createAsyncThunk('proposal/feature/edit-inline', async (data, { rejectWithValue }) => {
    const { featureId, estimationId, updatedValue } = data;
    try {
        const response = await proposalGateway.patch(
            `${serviceEndpoints.proposalFeature}/${featureId}/estimation/${estimationId}`,
            {
                hour: updatedValue,
            }
        );
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addProposalSubFeature = createAsyncThunk('proposal/feature/add/sub', async (data, { rejectWithValue }) => {
    try {
        const body = formatSubFatureParams(data);
        const response = await proposalGateway.post(serviceEndpoints.proposalSubFeature, body);
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addFeatureAbove = createAsyncThunk('proposal/feature/add/above', async (data, { rejectWithValue }) => {
    try {
        const { currentFeatureId, proposalId, moduleId, isSubFeature = false, parentId } = data;
        const { title, description, proposalFeatureEstimation, subFeatures } = formatRequestBody(data);
        let estimation = proposalFeatureEstimation;
        if (isSubFeature) {
            estimation = formatSubFatureParams(data)?.proposalFeatureEstimation;
        }
        const body = {
            title,
            description,
            proposalFeatureEstimation: estimation,
            subFeatures,
            currentFeatureId,
            proposalId,
            moduleId,
            isSubFeature,
            parentId,
        };
        const response = await proposalGateway.post(serviceEndpoints.proposalAddAbove, body);
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addFeatureBelow = createAsyncThunk('proposal/feature/add/below', async (data, { rejectWithValue }) => {
    try {
        const { currentFeatureId, proposalId, moduleId, isSubFeature = false, parentId } = data;
        const { title, description, proposalFeatureEstimation, subFeatures } = formatRequestBody(data);
        let estimation = proposalFeatureEstimation;
        if (isSubFeature) {
            estimation = formatSubFatureParams(data)?.proposalFeatureEstimation;
        }
        const body = {
            title,
            description,
            proposalFeatureEstimation: estimation,
            subFeatures,
            currentFeatureId,
            proposalId,
            moduleId,
            isSubFeature,
            parentId,
        };
        const response = await proposalGateway.post(serviceEndpoints.proposalAddBelow, body);
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const moveFeature = createAsyncThunk('proposal/feature/move', async (data, { rejectWithValue }) => {
    try {
        const { condition, ...body } = data;
        const response = await proposalGateway.post(
            `${serviceEndpoints.proposalMoveFeature}?condition=${condition}`,
            body
        );
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteFeature = createAsyncThunk('proposal/feature/delete', async (data, { rejectWithValue }) => {
    try {
        const { id, isSubFeature } = data;
        const response = await proposalGateway.delete(
            `${serviceEndpoints.proposalFeature}/${id}?isSubFeature=${isSubFeature}`
        );
        const { success, message } = response.data;
        if (success) {
            Notifications(message, 'success');
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteProposalModule = createAsyncThunk('proposal/module/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.proposalModule}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const editProposalModule = createAsyncThunk(
    'proposal/feature/edit/module',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...body } = data;
            const response = await proposalGateway.put(`${serviceEndpoints.proposalModule}/${id}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue({ message });
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const editFeatureSubFeature = createAsyncThunk(
    'proposal/feature/edit/sub',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...rest } = data;
            const body = formatSubFatureParams(rest);
            const response = await proposalGateway.put(`${serviceEndpoints.proposalSubFeature}/${id}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue({ message });
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const editFeatureStatus = createAsyncThunk(
    'proposal/feature/change/status',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...body } = data;
            const response = await proposalGateway.put(`${serviceEndpoints.proposalChangStatus}/${id}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue({ message });
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getFeatureCalculation = createAsyncThunk(
    'featue/estimationCalculation',
    async (id, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.proposalFeature}/estimation-total/${id}`);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'proposalfeature',
    initialState: {
        isModuleLoading: false,
        isModuleCreated: false,
        isFeatureLoading: false,
        isFeatureCreated: false,
        isFeatureEdited: false,
        isModuleEdited: false,
        inLineEditStatus: false,
        message: '',
        successMessage: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(postModule.pending, (state) => {
                state.isModuleLoading = true;
            })
            .addCase(postModule.rejected, (state) => {
                state.isModuleLoading = false;
                state.isModuleCreated = false;
            })
            .addCase(postModule.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isModuleLoading = false;
                state.isModuleCreated = success;
            })
            .addCase(moveModule.pending, (state) => {
                state.isModuleEdited = true;
            })
            .addCase(moveModule.rejected, (state) => {
                state.isModuleEdited = false;
            })
            .addCase(moveModule.fulfilled, (state, { payload }) => {
                state.isModuleEdited = false;
            })
            .addCase(postFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(postFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureCreated = false;
            })
            .addCase(postFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureCreated = success;
            })
            .addCase(editProposalFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(editProposalFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(editProposalFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(editFeatureSubFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(editFeatureSubFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(editFeatureSubFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(addProposalSubFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(addProposalSubFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(addProposalSubFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(addFeatureAbove.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(addFeatureAbove.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(addFeatureAbove.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(addFeatureBelow.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(addFeatureBelow.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(addFeatureBelow.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(moveFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(moveFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(moveFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(deleteFeature.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(deleteFeature.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(deleteFeature.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(deleteProposalModule.pending, (state) => {
                state.isFeatureLoading = true;
            })
            .addCase(deleteProposalModule.rejected, (state) => {
                state.isFeatureLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(deleteProposalModule.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isFeatureLoading = false;
                state.isFeatureEdited = success;
            })
            .addCase(editProposalModule.pending, (state) => {
                state.isModuleLoading = true;
            })
            .addCase(editProposalModule.rejected, (state) => {
                state.isModuleLoading = false;
                state.isFeatureEdited = false;
            })
            .addCase(editProposalModule.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isModuleLoading = false;
                state.isModuleEdited = success;
            })
            .addCase(editFeatureStatus.pending, (state) => {
                state.isFeatureEdited = false;
            })
            .addCase(editFeatureStatus.rejected, (state) => {
                state.isFeatureEdited = false;
            })
            .addCase(editFeatureStatus.fulfilled, (state, { payload }) => {
                const { success, message, data } = payload;
                state.editCompleted = data.featureId;
                state.successMessage = message;
                state.isFeatureEdited = success;
            })
            .addCase(editFeatureInline.pending, (state) => {
                state.inLineEditStatus = false;
            })
            .addCase(editFeatureInline.fulfilled, (state) => {
                state.inLineEditStatus = true;
            })
            .addCase(editFeatureInline.rejected, (state) => {
                state.inLineEditStatus = false;
            })
            .addCase(getFeatureCalculation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFeatureCalculation.rejected, (state) => {
                state.isLoading = false;
                state.estimationCalculations = {};
            })
            .addCase(getFeatureCalculation.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.estimationCalculations = payload.data;
            })
            .addCase('clearAllFeature', (state) => {
                state.isModuleLoading = false;
                state.isModuleCreated = false;
                state.isFeatureEdited = false;
                state.isFeatureCreated = false;
                state.isModuleEdited = false;
                state.inLineEditStatus = false;
                state.message = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
