import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const getDashboardCount = createAsyncThunk('dashboard/count', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.dashboard}/counts?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getConversionChart = createAsyncThunk('dashboard/conversionChart', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(
            `${serviceEndpoints.dashboard}/chart/conversion?${getQueryParams(data)}`
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getProposalChart = createAsyncThunk('dashboard/proposalChart', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(
            `${serviceEndpoints.dashboard}/chart/proposal?${getQueryParams(data)}`
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'dashboard',
    initialState: {
        isCountLoading: true,
        dashboardCount: {},
        conversionData: {},
        proposalData: {},
    },

    extraReducers: (builder) => {
        builder
            .addCase(getDashboardCount.pending, (state) => {
                state.isCountLoading = true;
            })
            .addCase(getDashboardCount.rejected, (state) => {
                state.isCountLoading = false;
                state.dashboardCount = {};
            })
            .addCase(getDashboardCount.fulfilled, (state, { payload }) => {
                state.isCountLoading = false;
                state.dashboardCount = payload;
            })
            .addCase(getConversionChart.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getConversionChart.rejected, (state) => {
                state.isLoading = false;
                state.conversionData = {};
            })
            .addCase(getConversionChart.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.conversionData = payload;
            })
            .addCase(getProposalChart.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProposalChart.rejected, (state) => {
                state.isLoading = false;
                state.proposalData = {};
            })
            .addCase(getProposalChart.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.proposalData = payload;
            });
    },
});

export default slice.reducer;
