const serviceEndpoints = {
    login: '/auth/login',
    google: '/auth/sign-in/google',
    employee: '/employee',
    employeeList: '/employee/list',
    empChangePassword: '/employee/password',
    employeeChangePassword: '/employee/password',
    employeeActivate: '/activate',
    employeeDeactivate: '/deactivate',
    forget: '/auth/forgotPassword',
    reset: '/auth/resetPassword',
    document: '/document',
    designation: '/designation',
    designationList: '/designation/list',
    profile: '/user/profile',
    document_download: 'document/download',
    permission: '/permission',
    userPermission: '/permission/user',
    passChange: '/user/password',
    proposal: '/proposal',
    proposalFeature: '/proposal/feature',
    proposalMoveFeature: '/proposal/feature/move',
    proposalSubFeature: '/proposal/feature/subFeature',
    proposalChangStatus: '/proposal/feature/changeStatus',
    proposalAddAbove: '/proposal/feature/add-above',
    proposalAddBelow: '/proposal/feature/add-below',
    proposalModule: '/proposal/module',
    proposalDownload: '/proposal/download',
    proposalEstimation: '/tender/estimation',
    proposalMove: '/proposal/move-to-conversion',
    proposalDeactivate: '/proposal/deactivate',
    proposalActivate: '/proposal/activate',
    proposalIntegration: '/pms-integration/project-list',
    createIntegration: '/pms-integration/create-task',
    techstack: '/proposal/technology',
    publicFeature: 'tender/feature',
    publicEstimationTotal: 'tender/estimation-total',
    dashboard: '/dashboard',
    conversions: '/conversions',
    conversionsStatus: '/conversions/status',
    activityLog: '/activity-log',
    proposalInfo: '/tender/info',
    domains: '/portfolio/domain',
    smtp: '/portfolio/smtp-settings',
    portfolio: '/portfolio',
    projects: '/portfolio/project',
    template: 'portfolio/template',
    prospectTemplate: 'portfolio/prospect-template',
    pmsTechnologies: '/pms-integration/technology-list',
    techcategory: '/portfolio/technology-stack',
    technology: '/portfolio/technology',
    message: '/portfolio/message',
    whiteListIP: '/portfolio/whitelisted-ip',
    sendMail: '/send-email',
    signInOtp: 'auth/sign-in/otp',
    otpSignIn: 'auth/otp/sign-in',
    suggestion: '/proposal/feature/suggestion',
    client: '/client',
    scrollCount: '/view-logs/scroll-count',
    viewLogs: '/view-logs',
    setDefault: '/set-default',
    imageCount: 'per-project-image-count',
    imageSettings: '/portfolio/settings',
    refresh: 'auth/token/refresh',
    upWork: '/portfolio/upwork-settings',
};
export default serviceEndpoints;
