import CryptoJS from 'crypto-js';

export const encryptAccessToken = (accessToken) => {
    const encryptedToken = CryptoJS.AES.encrypt(accessToken, 'AKC9EXZ1857ahkXL').toString();

    return encryptedToken;
};

export const decryptAccessToken = (encryptedToken) => {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, 'AKC9EXZ1857ahkXL');
    const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedAccessToken;
};
