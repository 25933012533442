import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

// Create a new template
export const postTemplate = createAsyncThunk('portfolio-template/Post', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const body = data;
    try {
        const response = await proposalGateway.post(serviceEndpoints.prospectTemplate, body, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All Template
export const getTemplate = createAsyncThunk('portfolio-template/List', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.prospectTemplate}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All Template
export const getTemplateId = createAsyncThunk('portfolio-template/GetById', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.prospectTemplate}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Update a new Template
export const updateTemplate = createAsyncThunk('portfolio-template/Edit', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const body = data;

    try {
        const response = await proposalGateway.put(`${serviceEndpoints.prospectTemplate}/${body.id}`, data.formData, {
            headers,
        });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete Template
export const deleteTemplate = createAsyncThunk('portfolio-template/Delete', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.prospectTemplate}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const setDefaultTemplate = createAsyncThunk(
    'portfolio-template/SetDefault',
    async (data, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.put(
                `${serviceEndpoints.prospectTemplate}/${data}${serviceEndpoints.setDefault}`
            );
            if (response.status < 200 || response.status >= 300) {
                return rejectWithValue(response.data);
            }
            return response.data;
        } catch (error) {
            if ((error.response && error.response.status === 401) || error.response.status === 400) {
                return rejectWithValue(error.response.data);
            }
        }
    }
);

const slice = createSlice({
    name: 'prospectTemplate',
    initialState: {
        errorMessage: '',
        successMessage: '',
        prospectTemplateList: {},
        singleTemplateData: {},
        isLoading: false,
        isTempLoading: false,
        prospectTemplateData: {},
    },

    extraReducers: (builder) => {
        builder
            .addCase(getTemplate.pending, (state) => {
                state.isTempLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.prospectTemplateList = [];
            })
            .addCase(getTemplate.rejected, (state, { payload }) => {
                state.isTempLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.prospectTemplateList = [];
            })
            .addCase(getTemplate.fulfilled, (state, { payload }) => {
                state.isTempLoading = false;
                state.errorMessage = '';
                state.prospectTemplateList = payload || null;
            })

            .addCase(getTemplateId.pending, (state) => {
                state.isTempLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.prospectTemplateData = {};
            })
            .addCase(getTemplateId.rejected, (state, { payload }) => {
                state.isTempLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.prospectTemplateData = {};
            })
            .addCase(getTemplateId.fulfilled, (state, { payload }) => {
                state.isTempLoading = false;
                state.errorMessage = '';
                state.prospectTemplateData = payload || null;
            })

            .addCase(postTemplate.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postTemplate.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postTemplate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase(updateTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTemplate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })
            .addCase(updateTemplate.rejected, (state, { payload }) => {
                state.singleTemplateData = {};
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase(deleteTemplate.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteTemplate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteTemplate.rejected, (state, { payload }) => {
                state.singleTemplateData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(setDefaultTemplate.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(setDefaultTemplate.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(setDefaultTemplate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
