import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const storeStep1 = createAction('storeStep1');
export const storeStep2 = createAction('storeStep2');
export const storeStep4 = createAction('storeStep4');

export const storeTech = createAction('storeTech');
export const storeRes = createAction('storeRes');
export const defaultFlag = createAction('defaultFlag');

export const clearTech = createAction('clearTech');
export const clearRes = createAction('clearRes');

export const clearAll = createAction('clearAll');
export const clearMsg = createAction('clearMsg');

export const postProposal = createAsyncThunk('proposal/create', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.post(serviceEndpoints.proposal, data, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const editProposal = createAsyncThunk('proposal/edit', async ({ data, id }, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.proposal}/${id}`, data, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const moveConvertionProposal = createAsyncThunk('proposal/moveConvertion', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.proposalMove}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deactivateProposal = createAsyncThunk('proposal/deactivate', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.proposalDeactivate}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const activateProposal = createAsyncThunk('proposal/activate', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.proposalActivate}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const changeProposal = createAsyncThunk('proposal/statuschange', async ({ data, id }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.proposal}/${id}/status`, data);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTechnology = createAsyncThunk('proposal/technology', async (_, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(serviceEndpoints.techstack);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getTechnologyUser = createAsyncThunk('proposal/technologyUser', async (_, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.techstack}/user`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue({ message });
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createDuplicate = createAsyncThunk(
    'proposal/duplicateProposal',
    async ({ id, newProposalName }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.post(`${serviceEndpoints.proposal}/duplicate/${id}`, {
                newProposalName,
            });
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue({ message });
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const slice = createSlice({
    name: 'createproposal',
    initialState: {
        isCreated: false,
        isEdited: false,
        isMoved: false,
        isDeactivate: false,
        isActivated: false,
        isDuplicated: false,
        message: '',
        statusChange: false,
        isLoading: false,
        techStack: [],
        techUser: [],
        addedResource: {},
        modifiedStack: [],
        step1: {},
        step2: {},
        step4: {},
        initFlag: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(postProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postProposal.rejected, (state) => {
                state.isLoading = false;
                state.isCreated = false;
            })
            .addCase(postProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isCreated = success;
            })

            .addCase(editProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editProposal.rejected, (state) => {
                state.isLoading = false;
                state.isEdited = false;
            })
            .addCase(editProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isEdited = success;
            })

            .addCase(changeProposal.pending, (state) => {
                state.statusChange = false;
            })
            .addCase(changeProposal.rejected, (state) => {
                state.statusChange = false;
            })
            .addCase(changeProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.statusChange = success;
            })

            .addCase(moveConvertionProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(moveConvertionProposal.rejected, (state) => {
                state.isLoading = false;
                state.isMoved = false;
            })
            .addCase(moveConvertionProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isMoved = success;
            })

            .addCase(deactivateProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deactivateProposal.rejected, (state) => {
                state.isLoading = false;
                state.isDeactivate = false;
            })
            .addCase(deactivateProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isDeactivate = success;
            })

            .addCase(activateProposal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(activateProposal.rejected, (state) => {
                state.isLoading = false;
                state.isActivated = false;
            })
            .addCase(activateProposal.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isActivated = success;
            })

            .addCase(getTechnology.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTechnology.rejected, (state) => {
                state.isLoading = false;
                state.techStack = {};
            })
            .addCase(getTechnology.fulfilled, (state, { payload }) => {
                const { technologies } = payload;
                state.isLoading = false;
                state.techStack = technologies;
            })

            .addCase(getTechnologyUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTechnologyUser.rejected, (state) => {
                state.isLoading = false;
                state.techUser = {};
            })
            .addCase(getTechnologyUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.techUser = payload?.data || [];
            })

            .addCase('storeStep1', (state, { payload }) => {
                state.step1 = payload;
            })
            .addCase('storeStep2', (state, { payload }) => {
                state.step2 = payload;
            })
            .addCase('storeStep4', (state, { payload }) => {
                state.step4 = payload;
            })
            .addCase('storeTech', (state, { payload }) => {
                state.modifiedStack = payload;
            })
            .addCase('storeRes', (state, { payload }) => {
                state.addedResource = payload;
            })
            .addCase('defaultFlag', (state, { payload }) => {
                state.initFlag = payload;
            })
            .addCase('clearTech', (state) => {
                state.modifiedStack = [];
            })
            .addCase('clearRes', (state) => {
                state.addedResource = {};
            })
            .addCase('clearAll', (state) => {
                state.modifiedStack = [];
                state.step1 = {};
                state.step2 = {};
                state.step4 = {};
                state.addedResource = {};
                state.initFlag = false;
                state.isCreated = false;
                state.isEdited = false;
                state.isMoved = false;
                state.isDeactivate = false;
                state.isActivated = false;
                state.isDuplicated = false;
                state.message = '';
            })
            .addCase('clearMsg', (state) => {
                state.message = '';
                state.statusChange = false;
            })
            .addCase(createDuplicate.pending, (state) => {
                state.isLoading = true;
                state.isDuplicated = false;
            })
            .addCase(createDuplicate.rejected, (state) => {
                state.isLoading = false;
                state.isDuplicated = false;
            })
            .addCase(createDuplicate.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.message = message;
                state.isLoading = false;
                state.isDuplicated = true;
            });
    },
});

export default slice.reducer;
