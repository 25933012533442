export const modulePermissions = (userPermissions, moduleName) =>
    userPermissions.find(({ module }) => moduleName === module)?.permissions?.map(({ permission }) => permission) ||
    userPermissions
        .find(({ subModules }) => subModules?.find((i) => i.module === moduleName))
        ?.subModules?.find((i) => i.module === moduleName)
        ?.permissions.map((i) => i.permission) ||
    [];

export const modulePermissionHasPermissions = (modulePermissionList, permissionArray) => {
    if (typeof permissionArray === 'string') {
        return modulePermissionList?.includes(permissionArray);
    }
    let includes = false;
    permissionArray.forEach((permission) => {
        if (modulePermissionList?.includes(permission)) {
            includes = true;
        }
    });
    return includes;
};

export const subModulesPermissions = (userPermissions, moduleName) =>
    userPermissions.find(({ module }) => moduleName === module)?.subModules?.map(({ subModule }) => subModule);

export const subModulePermissionHasPermissions = (modulePermissionList, permissionArray) => {
    if (typeof permissionArray === 'string') {
        return modulePermissionList?.includes(permissionArray);
    }
    let includes = false;
    permissionArray.forEach((subModule) => {
        if (modulePermissionList?.includes(subModule)) {
            includes = true;
        }
    });
    return includes;
};
