import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const resetMsg = createAction('resetMsg');

export const getImageSettings = createAsyncThunk('get-imageSettings', async (data, { rejectWithValue }) => {
    const body = data;
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.imageSettings}`, body);
        const { success, message } = response.data;

        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const postImageSettings = createAsyncThunk('add-imageSettings', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.put(serviceEndpoints.imageSettings, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'imagesettings',
    initialState: {
        errorMessage: '',
        successMessage: '',
        imageSettingData: {},
        isLoading: false,
        isImageDataLoading: false,
        isImageDataUpdating: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getImageSettings.pending, (state) => {
                state.isLoading = true;
                state.isImageDataLoading = true;
                state.imageSettingData = {};
                state.isUpdated = false;
            })
            .addCase(getImageSettings.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isImageDataLoading = false;
                state.imageSettingData = {};
                state.errorMessage = payload.message;
            })
            .addCase(getImageSettings.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isImageDataLoading = false;
                state.imageSettingData = payload?.portfolioSettings || {};
            })
            .addCase(postImageSettings.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isImageDataUpdating = true;
            })
            .addCase(postImageSettings.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.isImageDataUpdating = false;
            })
            .addCase(postImageSettings.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
                state.isImageDataUpdating = false;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
