import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
// import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getUpWork = createAsyncThunk('get-upwork', async (data, { rejectWithValue }) => {
    const body = data;
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.upWork}`, body);
        const { success, message } = response.data;

        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const postUpWork = createAsyncThunk('add-upwork', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.post(serviceEndpoints.upWork, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'upWork',
    initialState: {
        errorMessage: '',
        successMessage: '',
        upWorkData: {},
        isLoading: false,
        isLoadingPost: false,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getUpWork.pending, (state) => {
                state.isLoading = true;
                state.upWorkData = {};
            })
            .addCase(getUpWork.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.upWorkData = {};
                state.errorMessage = payload?.message;
            })
            .addCase(getUpWork.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.upWorkData = payload?.data || {};
            })
            .addCase(postUpWork.pending, (state) => {
                state.isLoadingPost = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postUpWork.rejected, (state, { payload }) => {
                state.isLoadingPost = false;
                state.errorMessage = payload;
                state.successMessage = '';
            })
            .addCase(postUpWork.fulfilled, (state, { payload }) => {
                state.isLoadingPost = false;
                state.errorMessage = '';
                state.successMessage = payload?.message;
            })

            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
