import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
// import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getSMTP = createAsyncThunk('get-smtp', async (data, { rejectWithValue }) => {
    const body = data;
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.smtp}`, body);
        const { success, message } = response.data;

        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const postSMTP = createAsyncThunk('add-smtp', async (data, { rejectWithValue }) => {
    // const headers = {
    //     'Content-Type': 'multipart/form-data',
    // };
    const body = { ...data };

    try {
        const response = await proposalGateway.post(serviceEndpoints.smtp, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'smtp',
    initialState: {
        errorMessage: '',
        successMessage: '',
        smtpData: {},
        isLoading: false,
        isSmtpLoading: false,
        isSmtpUpdating: false,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getSMTP.pending, (state) => {
                state.isLoading = true;
                state.isSmtpLoading = true;
                state.smtpData = {};
                state.isUpdated = false;
            })
            .addCase(getSMTP.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isSmtpLoading = false;
                state.smtpData = {};
                state.errorMessage = payload.message;
            })
            .addCase(getSMTP.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSmtpLoading = false;
                state.smtpData = payload?.smtpSettings || {};
            })
            .addCase(postSMTP.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isSmtpUpdating = true;
            })
            .addCase(postSMTP.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.isSmtpUpdating = false;
            })
            .addCase(postSMTP.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
                state.isSmtpUpdating = false;
            })

            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
