import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

export const getDocumentList = createAsyncThunk('document-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.document}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const addDocument = createAsyncThunk('add-document', async (data, { rejectWithValue, dispatch }) => {
    const body = data;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.post(serviceEndpoints.document, body, { headers, timeout: 60000 });
        const { success, message } = response.data;
        if (success) {
            dispatch(
                getDocumentList({
                    currentPage: 1,
                    limit: 10,
                })
            );
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const downloadDocument = createAsyncThunk('document-download', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.document_download}/${id}`, {
            responseType: 'blob',
        });

        let contentType = response.headers['content-type'];
        if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            contentType = 'application/xlsx';
        } else if (contentType === 'application/msword') {
            contentType = 'application/doc';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            contentType = 'application/docx';
        } else if (contentType === 'application/vnd.ms-excel') {
            contentType = 'application/xls';
        }
        const fileExtension = contentType.split('/')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `document_${id}.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return response?.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Delete Document
export const deleteDocument = createAsyncThunk('delete-document', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.document}/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
const slice = createSlice({
    name: 'addDocument',
    initialState: {
        documentList: null,
        isLoading: false,
        isdocumentuploading: false,
        isDeletionLoading: false,
        errorMessage: '',
        successMessage: '',
        isDownloading: '',
        downloadmsg: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(addDocument.pending, (state) => {
                state.isLoading = true;
                state.isdocumentuploading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(addDocument.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isdocumentuploading = false;
                state.errorMessage = payload;
                state.successMessage = '';
            })
            .addCase(addDocument.fulfilled, (state, { payload }) => {
                const { message } = payload;
                state.isLoading = false;
                state.isdocumentuploading = false;
                state.successMessage = message;
            })
            .addCase(getDocumentList.pending, (state) => {
                state.isLoading = true;
                state.documentList = {};
            })
            .addCase(getDocumentList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.documentList = {};
            })
            .addCase(getDocumentList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.documentList = payload.data || null;
            })
            .addCase(downloadDocument.pending, (state) => {
                state.isDownloading = true;
                state.downloadmsg = 'Downloading';
            })
            .addCase(downloadDocument.rejected, (state, { payload }) => {
                const { message } = payload;
                state.isDownloading = false;
                state.errorMessage = message;
            })
            .addCase(downloadDocument.fulfilled, (state) => {
                state.isDownloading = false;
            })
            .addCase(deleteDocument.pending, (state) => {
                state.isDeletionLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteDocument.fulfilled, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteDocument.rejected, (state, { payload }) => {
                state.isDeletionLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
