import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
import { getQueryParams } from '../../../utils';

export const resetMsg = createAction('resetMsg');

// Create a new client
export const postClient = createAsyncThunk('add-client', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const body = { ...data };
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.client}?userType=${data.userType}`, body, {
            headers,
        });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All employees
export const getClientDetails = createAsyncThunk('list-client', async (data, { rejectWithValue }) => {
    try {
        const { ...params } = data;
        const response = await proposalGateway.get(`${serviceEndpoints.client}?${getQueryParams(params)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Update a new user
export const updateClient = createAsyncThunk('update-employee', async (data, { rejectWithValue }) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.client}/${data.id}`, data, { headers });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Get employee by id
export const getClientById = createAsyncThunk('get-client-By-Id', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.client}/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete Employee
export const deleteClient = createAsyncThunk('delete-client', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.client}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const fileExportClient = createAsyncThunk('export-client', async (data, { rejectWithValue }) => {
    const headers = {
        'x-timezone-offset': -new Date().getTimezoneOffset(),
        'x-response-format': 'excel',
    };
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.client}?${getQueryParams(data)}`, { headers });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'client',
    initialState: {
        errorMessage: '',
        successMessage: '',
        clientData: [],
        employeeList: [],
        singleClientData: {},
        passwdUpdate: false,
        isClientLoading: false,
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
        isClientDownloading: false,
        exportData: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClientDetails.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
                state.clientData = [];
            })
            .addCase(getClientDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.clientData = [];
            })
            .addCase(getClientDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.clientData = payload || null;
            })
            .addCase(postClient.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postClient.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postClient.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase(updateClient.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateClient.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(updateClient.rejected, (state, { payload }) => {
                state.singleClientData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(deleteClient.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteClient.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteClient.rejected, (state, { payload }) => {
                state.singleClientData = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(getClientById.pending, (state) => {
                state.isClientLoading = true;
                state.errorMessage = '';
                state.entityParams = '';
            })
            .addCase(getClientById.fulfilled, (state, { payload }) => {
                state.isClientLoading = false;
                state.singleClientData = payload;
            })
            .addCase(getClientById.rejected, (state, { payload }) => {
                state.singleClientData = {};
                state.isClientLoading = false;
            })

            .addCase(fileExportClient.pending, (state) => {
                state.isClientDownloading = true;
            })
            .addCase(fileExportClient.fulfilled, (state, { payload }) => {
                if (payload?.clients?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.clients?.url;
                    link.download = payload?.clients?.filename;
                    link.click();
                }

                state.isClientDownloading = false;
                state.exportData = payload;
            })
            .addCase(fileExportClient.rejected, (state) => {
                state.isClientDownloading = false;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
