import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../../config/serviceEndpoints';
import proposalGateway from '../../../../config/service';
import { getQueryParams } from '../../../../utils';

export const resetMsg = createAction('resetMsg');
export const clearPortfolio = createAction('clearPortfolio');
// get PortFolio

export const getPortFolioList = createAsyncThunk('portfolio-list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.portfolio}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// post PortFolio
export const createPortfolio = createAsyncThunk('portfolio-add', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(serviceEndpoints.portfolio, data.PortfolioData);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// update  Portfolios
export const updatePortfolios = createAsyncThunk('update-portfolios', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.portfolio}/${data.id}`, data.PortfolioData);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// delete Portfolios
export const deletePortfolios = createAsyncThunk('delete-portfolios', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.portfolio}/${data.id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// get Portfolios by id
export const getPortfolioById = createAsyncThunk(
    'get-portfolios-By-Id',
    async ({ id, withCred = false }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.portfolio}/${id}`, {
                withCredentials: withCred,
            });
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const updatePortFolioProjects = createAsyncThunk(
    'updata-portfolios-By-Id-project',
    async ({ id, projects }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.put(`${serviceEndpoints.portfolio}/${id}/projects`, { projects });
            if (response.status < 200 || response.status >= 300) {
                return rejectWithValue(response.data);
            }
            return response.data;
        } catch (error) {
            if ((error.response && error.response.status === 401) || error.response.status === 400) {
                return rejectWithValue(error.response.data);
            }
        }
    }
);
// sendEmail PortFolio
export const sendEmailPortfolio = createAsyncThunk(
    'portfolio-send-emails',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.post(
                `${serviceEndpoints.portfolio}/${id}${serviceEndpoints.sendMail}`,
                data
            );
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

//  Increment viewLogs portfolio
export const viewLogsPortfolio = createAsyncThunk('viewlogs-portfolio', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(
            `${serviceEndpoints.portfolio}/${id}${serviceEndpoints.viewLogs}`,
            {},
            { withCredentials: true }
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// get viewlogs
export const getViewLogsPortfolio = createAsyncThunk('get-viewlogs-portfolio', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.portfolio}/${id}${serviceEndpoints.viewLogs}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Scroll count.
export const scrollCount = createAsyncThunk('get-scroll-count', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(
            `${serviceEndpoints.portfolio}/${id}${serviceEndpoints.scrollCount}`,
            {},
            { withCredentials: true }
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
// Generate Pdf
export const generatePdf = createAsyncThunk('pdf-generate', async ({ id, uid }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(
            `${serviceEndpoints.portfolio}/${id}/generate-pdf?${getQueryParams({ jobId: uid })}`,
            {
                timeout: 90000,
            }
        );
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// View Log
export const getViewLog = createAsyncThunk('view-log-list', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(
            `${serviceEndpoints.portfolio}/${id}${serviceEndpoints.viewLogs}?${getQueryParams(data)}`
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const imageCount = createAsyncThunk('image-count', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(
            `${serviceEndpoints.portfolio}/${data.id}/${serviceEndpoints.imageCount}`,
            data.body
        );
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'portFolio',
    initialState: {
        portFolioList: [],
        portFolioLogs: [],
        isLoading: false,
        isLogLoading: false,
        updateSuccess: false,
        singlePortfolioData: {},
        errorMessage: '',
        successMessage: '',
        isDownloading: false,
        portfolioError: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(getPortFolioList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPortFolioList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.portFolioList = [];
            })
            .addCase(getPortFolioList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.portFolioList = payload || [];
            })
            .addCase(getViewLog.pending, (state) => {
                state.isLogLoading = true;
            })
            .addCase(getViewLog.rejected, (state, { payload }) => {
                state.isLogLoading = false;
                state.errorMessage = payload.message;
                state.portFolioLogs = [];
            })
            .addCase(getViewLog.fulfilled, (state, { payload }) => {
                state.isLogLoading = false;
                state.portFolioLogs = payload || [];
            })

            .addCase(createPortfolio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createPortfolio.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(createPortfolio.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })
            .addCase(updatePortfolios.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updatePortfolios.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })
            .addCase(updatePortfolios.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase(deletePortfolios.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deletePortfolios.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deletePortfolios.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(getPortfolioById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPortfolioById.rejected, (state, { payload }) => {
                state.portfolioError = payload;
                state.isLoading = false;
                state.singlePortfolioData = {};
            })
            .addCase(getPortfolioById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.singlePortfolioData = payload;
            })
            .addCase(updatePortFolioProjects.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(updatePortFolioProjects.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.updateSuccess = true;
                state.successMessage = payload?.message;
            })
            .addCase(updatePortFolioProjects.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase(sendEmailPortfolio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendEmailPortfolio.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase(sendEmailPortfolio.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })
            .addCase(imageCount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(imageCount.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload?.message;
            })
            .addCase(imageCount.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload?.message;
            })

            .addCase(generatePdf.pending, (state) => {
                state.isDownloading = true;
            })
            .addCase(generatePdf.fulfilled, (state, { payload }) => {
                if (payload?.pdf?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.pdf?.url;
                    link.download = payload?.pdf?.filename;
                    link.click();
                    state.isDownloading = false;
                }

                state.exportData = payload;
            })
            .addCase(generatePdf.rejected, (state) => {
                state.isDownloading = false;
            })
            .addCase('clearPortfolio', (state) => {
                state.singlePortfolioData = {};
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
                state.updateSuccess = false;
            });
    },
});

export default slice.reducer;
