import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../components/auth/signIn/redux';
import { modulePermissions, modulePermissionHasPermissions } from '../../utils/permissionUtils';
import permisionConstants from '../../utils/permissionUtils/permisionConstants';
import hamIcon from '../../assets/img/ham-menu.svg';
import mobileLogo from '../../assets/img/logo-small.svg';
import profileIcon from '../../assets/img/profile-icon.svg';
import changePassIcon from '../../assets/img/change-pw-icon.svg';
import logoutIcon from '../../assets/img/logout.svg';
import defaultPic from '../../assets/img/user-pic.svg';
import { getProfile } from '../../components/profile/redux';
import { changeProposalSearch } from '../../components/proposalManagement/redux/getProposal';

const {
    modules: { PROPOSAL_MANAGEMENT },
    VIEW_PROPOSAL,
} = permisionConstants;

function Header({ collapse, setCollapse, getDarkMode }) {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);
    const [searching, setSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [toggle, setToggle] = useState(sessionStorage.getItem('darkMode'));
    const [darksMode, setDarksMode] = useState(sessionStorage.getItem('darkMode') === 'true');

    const { profileData } = useSelector((e) => e.profileReducer);
    const { designation } = useSelector((e) => e.permissionReducer);
    const { userPermissions } = useSelector((state) => state.permissionReducer);
    const { searchValue: permissionsSearch } = useSelector((state) => state.getProposalReducer);

    const permissionList = modulePermissions(userPermissions, PROPOSAL_MANAGEMENT);

    useEffect(() => {
        getDarkMode(darksMode);
    }, [darksMode]);
    useEffect(() => {
        if (
            permissionsSearch &&
            !permissionsSearch.includes('@') &&
            !location?.pathname?.includes('/proposal/proposals')
        ) {
            navigate('/proposal/proposals');
        }
        setSearchValue(permissionsSearch);
    }, [permissionsSearch]);

    useEffect(() => {
        if (!profileData?.firstName && location.pathname !== '/profile') {
            dispatch(getProfile('basic'));
        }
    }, []);

    const setdarkMode = () => {
        document.body.classList.add('dark-mode');
        sessionStorage.setItem('darkMode', true);
        setDarksMode(true);
    };
    const removedarkMode = () => {
        document.body.classList.remove('dark-mode');
        sessionStorage.removeItem('darkMode');
        setDarksMode(false);
    };

    const triggerTheme = () => {
        const isdark = sessionStorage.getItem('darkMode');
        if (isdark) {
            removedarkMode();
        } else {
            setdarkMode();
        }
    };

    useEffect(() => {
        const isdark = sessionStorage.getItem('darkMode');
        if (isdark) {
            document.body.classList.add('dark-mode');
            // setDarksMode(true);
        }
    }, []);

    return (
        <div className="header">
            <div className="mobLogo">
                <Link
                    to={`/${
                        userPermissions[0]?.module_label.toLowerCase().replace(' ', '-').replace('_', '-') ||
                        'dashboard'
                    }`}
                    className="mobLogoLink"
                >
                    <img src={mobileLogo} alt="" />
                </Link>
            </div>

            <div
                className="mobHamb"
                onClick={() => {
                    setCollapse(!collapse);
                }}
            >
                <div className="collapsetoggle">
                    <span id="toggleIcon" className="toggle-btn">
                        <img src={hamIcon} alt="" />
                    </span>
                </div>
            </div>
            <div className="lft-sctn">
                <label className="switch">
                    <input
                        type="checkbox"
                        onChange={() => {
                            triggerTheme();
                            setToggle(!toggle);
                        }}
                        checked={toggle}
                    />
                    <span className="slider round" />
                </label>
                {modulePermissionHasPermissions(permissionList, VIEW_PROPOSAL) && (
                    <div className="search-bar">
                        <div className="mobSearchToggle" onClick={() => setSearching(!searching)} />
                        <div className={`searchINWrap ${searching ? 'opened' : ''}`}>
                            <input
                                type="text"
                                name="searchproposal"
                                className="search  input-shadow"
                                placeholder="Search proposal"
                                value={searchValue}
                                autoComplete="off"
                                onChange={({ target: { value } }) => {
                                    setSearchValue(value.trimStart());
                                    clearTimeout(timer);
                                    const timerSet = setTimeout(() => {
                                        dispatch(changeProposalSearch(value));
                                    }, 500);
                                    setTimer(timerSet);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="right-sctn">
                <div className="dropdown profile-drop custom-drop">
                    <div className="user-profile">
                        <img
                            className=" user-icon"
                            src={
                                profileData?.employee?.photoThumbUrl ||
                                profileData?.photoThumb ||
                                profileData?.client?.image
                                    ? profileData?.photoThumb || profileData?.client?.image
                                    : defaultPic
                            }
                            alt=""
                        />
                    </div>
                    <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <div className="name user-name">
                            {profileData?.client?.name || profileData?.name}
                            <br />
                            <span>{designation}</span>
                        </div>
                    </button>
                    <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                        <li>
                            <Link className="dropdown-item" to="/profile">
                                <span className="drop-icon">
                                    <img src={profileIcon} alt="" />
                                </span>
                                Profile
                            </Link>
                        </li>
                        {profileData?.type === 'Employee' && (
                            <li>
                                <Link className="dropdown-item" to="/change-password">
                                    <span className="drop-icon">
                                        <img src={changePassIcon} alt="" />
                                    </span>
                                    Change Password
                                </Link>
                            </li>
                        )}
                        <li>
                            <a className="dropdown-item" onClick={() => dispatch(logout())}>
                                <span className="drop-icon">
                                    <img src={logoutIcon} alt="" />
                                </span>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Header;
