import React from 'react';

export const components = {
    signIn: React.lazy(() => import('../components/auth/signIn/SignIn')),
    otp: React.lazy(() => import('../components/auth/otp/Otp')),
    resetPassword: React.lazy(() => import('../components/auth/resetPassword/ResetPassword')),
    forgetPassword: React.lazy(() => import('../components/auth/forgetPassword/ForgetPassword')),
    page404: React.lazy(() => import('../components/common/Page404')),
    dashboard: React.lazy(() => import('../components/dashboard/Dashboard')),
    employeesManagement: React.lazy(() => import('../components/employeesManagement/EmployeesManagement')),
    profile: React.lazy(() => import('../components/profile/Profile')),
    permissionManagement: React.lazy(() => import('../components/permissionManagement/PermissionManagement')),
    proposalManagement: React.lazy(() => import('../components/proposalManagement/ProposalManagment')),
    documents: React.lazy(() => import('../components/documents/Documents')),
    changePassword: React.lazy(() => import('../components/changePassword/ChangePassword')),
    conversions: React.lazy(() => import('../components/conversions/Conversions')),
    domains: React.lazy(() => import('../components/portfolio/Domains')),
    technologies: React.lazy(() => import('../components/portfolio/Technologies')),
    technologiesCategList: React.lazy(() => import('../components/portfolio/TechnologyCategList')),
    projects: React.lazy(() => import('../components/portfolio/Projects')),
    projectView: React.lazy(() => import('../components/portfolio/ProjectView')),
    portfolioList: React.lazy(() => import('../components/portfolio/portfolios/Portfolios')),
    portfolioView: React.lazy(() => import('../components/portfolio/portfolios/PortfolioView')),
    portfolioTemplate: React.lazy(() => import('../components/portfolio/PortfolioTemplate')),
    portfolioMessage: React.lazy(() => import('../components/portfolio/messages/Messages')),
    portfolioSMTP: React.lazy(() => import('../components/portfolio/settings/SMTP')),
    portfolioUpworkSettings: React.lazy(() => import('../components/portfolio/settings/UpworkSettings')),
    portfolioWhitelist: React.lazy(() => import('../components/portfolio/settings/WhiteList')),
    buisnessProposal: React.lazy(() => import('../components/businessProposal/BusinessProposal')),
    client: React.lazy(() => import('../components/clientManagement/Client')),
    publicPortfolio: React.lazy(() => import('../components/portfolio/portfolios/PortfolioPublic')),
    suggestions: React.lazy(() => import('../components/suggestions/Suggestions')),
    templatePublic: React.lazy(() => import('../components/portfolio/portfolios/TemplatePublic')),
    projectPublic: React.lazy(() => import('../components/portfolio/portfolios/ProjectPublic')),
    portfolioImageSettings: React.lazy(() => import('../components/portfolio/settings/ImageSetting')),
    errorPage: React.lazy(() => import('../components/portfolio/portfolios/ErrorPage')),
    proposalTemplate: React.lazy(() => import('../components/portfolio/Templates')),
    proposalFrd: React.lazy(() => import('../components/proposalFRD/ProposalFRD')),
    prospectManagement: React.lazy(() => import('../components/prospectManagement/ProspectManagement')),
};
