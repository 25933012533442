import React, { Suspense, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUserPermisions } from '../components/permissionManagement/redux/permissionSlice';
import { Notifications } from '../utils';
import { getDefaultRoute } from '../components/permissionManagement/util';

function PublicRoute({ children, isLoggedIn }) {
    const { isLoggedIn: userLoggedIn, resMsg } = useSelector((state) => state.signInReducer);
    const signInReducer = useSelector((state) => state.signInReducer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userPermissions } = useSelector((state) => state.permissionReducer);
    const { logInSuccessMessage } = useSelector((states) => states.otpReducer);

    useEffect(() => {
        if (isLoggedIn || userLoggedIn || logInSuccessMessage) {
            dispatch(getUserPermisions());
        }
    }, [isLoggedIn, userLoggedIn, logInSuccessMessage]);
    useEffect(() => {
        if (userPermissions.length) {
            Notifications(resMsg || logInSuccessMessage, 'success');
            navigate(getDefaultRoute(userPermissions));
        }
    }, [userPermissions]);
    return (
        <Suspense fallback={<div />}>
            {/* {isLoggedIn ? <Navigate to="/dashboard" /> : <div>{children}</div>} */}
            <GoogleOAuthProvider clientId="896196434330-n3p2udust3f94b2riamrkchv1tojq5ip.apps.googleusercontent.com">
                {isLoggedIn ? null : <div>{children}</div>}
            </GoogleOAuthProvider>
        </Suspense>
    );
}

export default PublicRoute;
