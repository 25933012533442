import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
import { getQueryParams } from '../../../utils';
import { formatRequestBody } from '../../proposalManagement/proposalUtils';

export const resetMsg = createAction('resetMsg');
export const clearSuggestion = createAction('clearSuggestion');

// Create a new suggestion
export const postSuggestion = createAsyncThunk('add-suggestion', async (data, { rejectWithValue }) => {
    const body = formatRequestBody(data);

    try {
        const response = await proposalGateway.post(serviceEndpoints.suggestion, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// List All suggestions
export const getSuggestionDetails = createAsyncThunk('list-suggestion', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.suggestion}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

// Update a new suggestion
export const updateSuggestion = createAsyncThunk('update-suggestion', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.suggestion}/${id}`, data);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Get suggestion by id
export const getSuggestionById = createAsyncThunk('get-suggestion-By-Id', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.suggestion}/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

// Delete Suggestion
export const deleteSuggestion = createAsyncThunk('delete-suggestion', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.suggestion}/${data}?isSubFeature=${false}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

const slice = createSlice({
    name: 'suggestions',
    initialState: {
        errorMessage: '',
        successMessage: '',
        suggestionData: [],
        singleSuggestionList: {},
        isEditLoading: false,
        isLoading: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getSuggestionDetails.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
                state.suggestionData = [];
            })
            .addCase(getSuggestionDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
                state.suggestionData = [];
            })
            .addCase(getSuggestionDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.suggestionData = payload || null;
            })
            .addCase(postSuggestion.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postSuggestion.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postSuggestion.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase(updateSuggestion.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSuggestion.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(updateSuggestion.rejected, (state, { payload }) => {
                state.singleSuggestionList = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(deleteSuggestion.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteSuggestion.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteSuggestion.rejected, (state, { payload }) => {
                state.singleSuggestionList = {};
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(getSuggestionById.pending, (state) => {
                state.isEditLoading = true;
                state.errorMessage = '';
                state.entityParams = '';
            })
            .addCase(getSuggestionById.fulfilled, (state, { payload }) => {
                state.isEditLoading = false;
                state.singleSuggestionList = payload;
            })
            .addCase(getSuggestionById.rejected, (state, { payload }) => {
                state.singleSuggestionList = {};
                state.isEditLoading = false;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase('clearSuggestion', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
                state.singleSuggestionList = {};
            });
    },
});

export default slice.reducer;
