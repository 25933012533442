import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import proposalGateway from '../../../config/service';
import serviceEndpoints from '../../../config/serviceEndpoints';
import { Notifications } from '../../../utils';

export const integrationStep1 = createAction('integrationStep1');
export const integrationStep2 = createAction('integrationStep2');
export const integrationStep3 = createAction('integrationStep3');
export const resetModal = createAction('resetModal');

export const getPmsProjects = createAsyncThunk('pmsIntegration/projectList', async (_, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(serviceEndpoints.proposalIntegration);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getPmsTechnologies = createAsyncThunk('pmsIntegration/techologies', async (_, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(serviceEndpoints.pmsTechnologies);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getPmsFeatureList = createAsyncThunk(
    'pmsIntegration/featureList',
    async (proposalId, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.get(`${serviceEndpoints.proposalFeature}/all/${proposalId}`);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const postIntegration = createAsyncThunk(
    'pmsIntegration/create-integration',
    async (params, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.post(serviceEndpoints.createIntegration, params);
            const { success, message } = response.data;
            if (success) {
                Notifications(message, 'success');
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'proposalIntegration',
    initialState: {
        pmsProjectList: null,
        step1: null,
        step2: null,
        step3: null,
        postSuccess: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase('integrationStep1', (state, { payload }) => {
                state.step1 = payload;
            })
            .addCase('integrationStep2', (state, { payload }) => {
                state.step2 = payload;
            })
            .addCase('integrationStep3', (state, { payload }) => {
                state.step3 = payload;
            })

            .addCase(getPmsProjects.pending, (state) => {})
            .addCase(getPmsProjects.rejected, (state) => {})
            .addCase(getPmsProjects.fulfilled, (state, { payload }) => {
                state.message = payload.message;
                state.pmsProjectList = payload.data;
            })
            .addCase(getPmsTechnologies.pending, (state) => {})
            .addCase(getPmsTechnologies.rejected, (state) => {})
            .addCase(getPmsTechnologies.fulfilled, (state, { payload }) => {
                state.message = payload.message;
                state.pmsTechnologyList = payload.data;
            })
            .addCase(getPmsFeatureList.pending, (state) => {})
            .addCase(getPmsFeatureList.rejected, (state) => {})
            .addCase(getPmsFeatureList.fulfilled, (state, { payload }) => {
                state.message = payload.message;
                state.pmsFeatureList = payload.result.feature;
            })
            .addCase(postIntegration.pending, (state) => {
                state.postSuccess = false;
            })
            .addCase(postIntegration.rejected, (state) => {
                state.postSuccess = false;
            })
            .addCase(postIntegration.fulfilled, (state, { payload }) => {
                state.postSuccess = true;
            })
            .addCase('resetModal', (state, { payload }) => {
                if (payload === 'clearStep3') {
                    state.step3 = null;
                }
                state.step1 = null;
                state.step2 = null;
                state.step3 = null;
                state.postSuccess = false;
            });
    },
});

export default slice.reducer;
