import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';

export const resetMsg = createAction('resetMsg');

// details=all
export const getProfile = createAsyncThunk('getProfile', async (type, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.profile}?details=${type}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getBasicProfile = createAsyncThunk('getProfile', async (data, { rejectWithValue }) => {
    const { type } = data;
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.profile}?details=${type}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const updateProfile = createAsyncThunk('/api/superAdmin/updateProfile', async (data, { rejectWithValue }) => {
    const body = data;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.profile}`, body, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
const slice = createSlice({
    name: 'profile',
    initialState: {
        profileData: {},
        isLoading: false,
        isProfileUpdating: false,
        isUpdated: false,
        errorMessage: '',
        successMessage: '',
        isProfileLoading: false,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getProfile.pending, (state) => {
                state.isLoading = true;
                state.isProfileLoading = true;
                state.profileData = {};
                state.isUpdated = false;
            })
            .addCase(getProfile.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isProfileLoading = false;
                state.profileData = {};
                state.errorMessage = payload.message;
            })
            .addCase(getProfile.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isProfileLoading = false;
                state.profileData = payload?.user || null;
            })
            .addCase(updateProfile.pending, (state) => {
                state.isProfileUpdating = true;
                state.isLoading = true;
                state.isUpdated = false;
            })
            .addCase(updateProfile.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isUpdated = false;
                state.isProfileUpdating = false;
                state.errorMessage = payload.message;
            })
            .addCase(updateProfile.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
                state.isProfileUpdating = false;
                state.isUpdated = payload.success;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
