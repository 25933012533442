/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
export const getAllFeatures = (proposalStructureData) =>
    proposalStructureData?.reduce(
        (accumalator, currentProposal) => [...accumalator, ...currentProposal.moduleFeatures],
        []
    );
export const calculateNewFeatureName = (currentData) => {
    const allFeatures = getAllFeatures(currentData);
    return `F_${String(allFeatures.length + 1).padStart(3, '0')}`;
};

export const getTotalHours = (currentData) => {
    const allFeatures = getAllFeatures(currentData);
    let totalHours = 0;
    allFeatures.forEach(({ proposal_estimations, subFeatures }) => {
        proposal_estimations.forEach(({ hour }) => {
            totalHours += isNaN(hour) ? 0 : Number(hour);
        });
        subFeatures.forEach(({ proposalFeatureEstimation }) => {
            proposalFeatureEstimation.forEach(({ hour: subhour }) => {
                totalHours += isNaN(subhour) ? 0 : Number(subhour);
            });
        });
    });
    return Math.round(totalHours)?.toFixed(2);
};

export const getQaHours = (tableData, qaPercentage) => {
    const totalHrs = parseFloat(getTotalHours(tableData));
    // eslint-disable-next-line radix
    return Math.round(((qaPercentage || 0) * totalHrs) / 100).toFixed(2);
};

export const getTotalNetHrs = (tableData, qaPercentage) => {
    const devTotal = parseFloat(getTotalHours(tableData));
    const qaTotal = parseFloat(getQaHours(tableData, qaPercentage));
    // eslint-disable-next-line radix
    return Math.round(devTotal + qaTotal).toFixed(2);
};
const formatProposalEstimations = (proposalEstimation, status) =>
    proposalEstimation?.map(({ hour, ...rest }) => ({
        hour: status === '0' ? (hour === '0' ? '' : hour || '0') : hour || '0',
        ...rest,
    }));
const formatModules = (id, features) => {
    const moduleFeatures = features
        ?.filter(({ moduleId }) => id === moduleId)
        ?.map(({ subFeatures, proposal_estimations: proposalEst, status, ...rest }) => ({
            ...rest,
            status,
            proposal_estimations: formatProposalEstimations(proposalEst, status),
            subFeatures: subFeatures.map(
                ({ proposal_estimations: proposalFeatureEstimation, statusSub, ...restFileds }) => ({
                    proposalFeatureEstimation: formatProposalEstimations(proposalFeatureEstimation, statusSub),
                    ...restFileds,
                })
            ),
        }));
    return moduleFeatures;
};

export const formatResult = ({ module = [], features = [] }) => {
    const moduleMap = module.map(({ id, name, note }) => ({
        id,
        name,
        note,
        moduleFeatures: formatModules(id, features),
    }));
    return moduleMap;
};
export const formatSubFatureParams = ({ proposalFeatureEstimation: subEstimation, id: subFeatureId, ...rest }) => ({
    proposalFeatureEstimation: subEstimation?.map(({ id, estimationId, hour }) => ({
        estimationId: estimationId || id,
        hour: hour || 0,
    })),
    subFeatureId,
    ...rest,
});
export const formatRequestBody = (data) => {
    const { featureId, proposalId, title, description, moduleId, featureEstimation } = data;
    const proposalFeatureEstimation = featureEstimation?.map(({ estimationId, hour }) => ({
        estimationId,
        hour: hour || 0,
    }));
    const subFeatures = data?.subFeatures?.map(formatSubFatureParams);
    return {
        featureId,
        proposalId,
        title,
        description,
        moduleId,
        proposalFeatureEstimation,
        subFeatures,
    };
};

export const generateTech = (arr) => {
    if (!arr?.length) return [];
    let tempObj = {};

    arr.forEach((item) => {
        if (item?.technology) {
            tempObj = {
                ...tempObj,
                [`${item?.technologyStack} (${item?.technology_stack.alias})`]: [item?.technology],
            };
        } else
            tempObj = {
                ...tempObj,
                [`${item?.technology_stack?.technologyStack} (${item?.technology_stack?.alias})`]:
                    item?.technologies?.map((x) => x?.technologyName),
            };
    });
    return tempObj;
};

export const isCellValid = (state, featureId, EstimationId) => {
    if (state?.featureId === featureId && state?.estimationId === EstimationId) return true;
    return false;
};
