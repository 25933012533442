import { createSlice, createAsyncThunk, createAction, current } from '@reduxjs/toolkit';
import proposalGateway from '../../../../config/service';
import serviceEndpoints from '../../../../config/serviceEndpoints';

export const resetmsg = createAction('resetmsg');

export const forgetPwd = createAsyncThunk('auth/forgetPwd', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await proposalGateway.post(serviceEndpoints.forget, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'forgetPassword',
    initialState: {
        resMsg: '',
        isLoading: false,
        isForget: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(forgetPwd.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isForget = false;
            })
            .addCase(forgetPwd.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload;
                state.isForget = false;
            })
            .addCase(forgetPwd.fulfilled, (state, { payload }) => {
                const { success, message } = payload;

                state.isLoading = false;
                state.resMsg = message;
                state.isForget = success;
            })
            .addCase('resetmsg', (state) => {
                state.isLoading = current(state).isLoading;
                state.isForget = current(state).isForget;
                state.resMsg = '';
            });
    },
});

export default slice.reducer;
