import dashboardIcon from '../../assets/img/icon-menu-dashboard.svg';
import employeeManagementIcon from '../../assets/img/icon-menu-group.svg';
import permissionManagementIcon from '../../assets/img/icon-menu-permission.svg';
import proposalManagementIcon from '../../assets/img/icon-menu-proposals-management.svg';
import conversationsIcon from '../../assets/img/icon-menu-conversions.svg';
import documentsIcon from '../../assets/img/icon-menu-documents.svg';
import permisionConstants from '../../utils/permissionUtils/permisionConstants';

const { modules } = permisionConstants;
export default [
    {
        label: 'Dashboard',
        path: '/dashboard',
        navIcon: dashboardIcon,
        permission: modules.DASHBOARD,
    },
    {
        label: 'Proposals',
        path: '/proposals',
        navIcon: proposalManagementIcon,
        permission: modules.PROPOSAL_MANAGEMENT,
    },
    {
        label: 'Conversions',
        path: '/conversions',
        navIcon: conversationsIcon,
        permission: modules.CONVERSIONS,
    },
    {
        label: 'Documents',
        path: '/documents',
        navIcon: documentsIcon,
        permission: modules.DOCUMENTS,
    },
    {
        label: 'Portfolio',
        path: '/portfolio/domains',
        navIcon: employeeManagementIcon,
        permission: 'TESTING',
        subMenus: [
            {
                sublabel: 'Domains',
                subpath: '/portfolio/domains',
            },
            {
                sublabel: 'Technologies',
                subpath: '/portfolio/technologies',
            },
            {
                sublabel: 'Projects',
                subpath: '/portfolio/projects',
            },
            {
                sublabel: 'Portfolios',
                subpath: '/portfolio/portfolios',
            },
            {
                sublabel: 'Templates',
                subpath: '/portfolio/templates',
            },
            {
                sublabel: 'Messages',
                subpath: '/portfolio/messages',
            },
            {
                sublabel: 'Settings',
                subpath: '/portfolio/settings',
                innerMenu: [
                    { innerlabel: 'SMTP Settings', innerpath: '/portfolio/settings/smtp' },
                    { innerlabel: 'Whitelist IP', innerpath: '/portfolio/settings/whitelist' },
                    { innerlabel: 'Image Settings', innerpath: '/portfolio/settings/image-settings' },
                ],
            },
        ],
    },
    {
        label: 'Suggestions',
        path: '/suggestions',
        // navIcon: employeeManagementIcon,
        permission: modules.SUGGESTIONS,
    },
    {
        label: 'Users',
        path: '/users',
        navIcon: employeeManagementIcon,
        permission: modules.EMPLOYEES_MANAGEMENT,
    },
    {
        label: 'Permission',
        path: '/permission-management',
        navIcon: permissionManagementIcon,
        permission: modules.PERMISSION_MANAGEMENT,
    },
];
