import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { encryptAccessToken } from '../../../../config/Encrypt';
import proposalGateway from '../../../../config/service';
import serviceEndpoints from '../../../../config/serviceEndpoints';

export const resetMsg = createAction('resetMsg');

export const postSignInOtp = createAsyncThunk('sign-in/otp', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.post(serviceEndpoints.signInOtp, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const postOtpSignIn = createAsyncThunk('otp/sign-in', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(serviceEndpoints.otpSignIn, data);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'otp',
    initialState: {
        errorMessage: '',
        successMessage: '',
        logInSuccessMessage: '',
        logInErrorMessage: '',
        isLoading: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(postSignInOtp.pending, (state) => {
                state.isLoading = true;
                state.logInErrorMessage = '';
                state.logInSuccessMessage = '';
            })
            .addCase(postSignInOtp.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.logInErrorMessage = payload.message;
                state.logInSuccessMessage = '';
            })
            .addCase(postSignInOtp.fulfilled, (state, { payload }) => {
                const {
                    user: { accessToken, email, phone, image, refreshToken },
                    success,
                    message,
                } = payload;
                if (success === true) {
                    const encryptedToken = encryptAccessToken(accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('accessToken', encryptedToken);
                    localStorage.setItem(
                        'clientDetails',
                        JSON.stringify({ email, name: payload?.user?.name, phone, image })
                    );
                }
                state.isLoading = false;
                state.logInErrorMessage = '';
                state.logInSuccessMessage = message;
            })

            .addCase(postOtpSignIn.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postOtpSignIn.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
                state.successMessage = '';
            })
            .addCase(postOtpSignIn.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = '';
                state.successMessage = payload.message;
            })
            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            });
    },
});

export default slice.reducer;
