import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { components } from './component';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { decryptAccessToken } from '../config/Encrypt';
import { getUserPermisions } from '../components/permissionManagement/redux/permissionSlice';
import permisionConstants from '../utils/permissionUtils/permisionConstants';

const { modules } = permisionConstants;

const RouteStructure = () => {
    const token = localStorage.getItem('accessToken');
    const isLoggedIn = !!token;

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn) dispatch(getUserPermisions());
    }, [location]);

    const { userPermissions } = useSelector((state) => state.permissionReducer);
    const { profileData } = useSelector((e) => e.profileReducer);
    const doesModuleHasPermission = (permission) =>
        userPermissions?.some(({ module, subModules = [] }) => {
            if (module === permission) return true;
            if (
                subModules?.some(
                    ({ module: subModule, permissions }) =>
                        permission === subModule ||
                        permissions.some(({ permission: innerModule }) => permission === innerModule)
                )
            )
                return true;
            return false;
        });
    const allRoutes = [
        {
            path: '/',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.signIn />
                </PublicRoute>
            ),
        },
        {
            path: '/forget-password',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.forgetPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/reset-password/:token',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.resetPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/portfolios/:id',
            element: (
                // <PublicRoute>
                <components.publicPortfolio />
                // </PublicRoute>
            ),
        },
        {
            path: '/portfolio/templates/:id',
            element: (
                // <PublicRoute>
                <components.templatePublic />
                // </PublicRoute>
            ),
        },
        {
            path: '/portfolio/templates/prospectTemplate/:id',
            element: (
                // <PublicRoute>
                <components.templatePublic />
                // </PublicRoute>
            ),
        },
        {
            path: '/otp',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.otp />
                </PublicRoute>
            ),
        },
        {
            path: '/dashboard',
            permission: modules.DASHBOARD,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.dashboard />
                </PrivateRoute>
            ),
        },
        {
            path: '/users/employees',
            permission: modules.EMPLOYEES_MANAGEMENT,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.employeesManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/users/permission',
            permission: modules.PERMISSION_MANAGEMENT,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.permissionManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/conversions',
            permission: modules.CONVERSIONS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.conversions />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/proposals',
            permission: modules.PROPOSAL_MANAGEMENT,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.proposalManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/suggestions',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.suggestions />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/client',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.client />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/proposals/:proposalId',
            permission: modules.PROPOSAL_MANAGEMENT,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.proposalManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.profile />
                </PrivateRoute>
            ),
        },
        {
            path: '/business-proposal/:proposalId',
            element: (
                // <PublicRoute>
                <components.buisnessProposal />
                // </PublicRoute>
            ),
        },
        {
            path: '/proposal-frd/:proposalId',
            element: (
                // <PublicRoute>
                <components.proposalFrd />
                // </PublicRoute>
            ),
        },
        {
            path: '/business-project/:projectId',
            element: (
                // <PublicRoute>
                <components.projectPublic />
                // </PublicRoute>
            ),
        },
        {
            path: '/documents',
            permission: modules.DOCUMENTS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.documents />
                </PrivateRoute>
            ),
        },
        {
            path: '/change-password',
            role: 'Employee',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.changePassword />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/domains',
            permission: modules.DOMAINS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.domains />
                </PrivateRoute>
            ),
        },
        {
            path: '/technologies/category',
            permission: modules.TECHNOLOGIES,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.technologiesCategList />
                </PrivateRoute>
            ),
        },
        {
            path: '/technologies/technology',
            permission: modules.TECHNOLOGIES,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.technologies />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/projects',
            permission: modules.PROJECTS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.projects />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/projects/:id',
            permission: modules.PROJECTS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.projectView />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/portfolios',
            permission: modules.PORTFOLIOS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioList />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/portfolios/:id',
            permission: modules.PORTFOLIOS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioView />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/settings/smtp-settings',
            permission: modules.SMTP_SETTINGS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioSMTP />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/settings/image-settings',
            // permission: modules.SMTP_SETTINGS,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioImageSettings />
                </PrivateRoute>
            ),
        },
        {
            path: '/proposal/templates',
            permission: modules.TEMPLATES,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.proposalTemplate />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/messages',
            permission: modules.MESSAGES,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioMessage />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/template',
            permission: modules.PROSPECT_TEMPLATE,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioTemplate />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/newlead',
            // permission: modules.PROSPECTS,
            permission: modules.NEWLEAD,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.prospectManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/settings/whitelist-ip',
            permission: modules.WHITELIST_IP,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioWhitelist />
                </PrivateRoute>
            ),
        },
        {
            path: '/portfolio/settings/upwork-settings',
            // permission: modules.WHITELIST_IP,
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.portfolioUpworkSettings />
                </PrivateRoute>
            ),
        },
        {
            path: '/jobs-automation',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.jobAutomation />
                </PrivateRoute>
            ),
        },
        {
            path: '*',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.page404 />
                </PrivateRoute>
            ),
        },
        {
            path: '/errorPage',
            element: (
                // <PublicRoute>
                <components.errorPage />
                // </PublicRoute>
            ),
        },
    ];
    return allRoutes.filter(({ permission, role }) =>
        permission ? doesModuleHasPermission(permission) : !role || role === profileData?.type
    );
};

export default RouteStructure;
