import { createSlice, createAsyncThunk, createAction, current } from '@reduxjs/toolkit';
import { encryptAccessToken } from '../../../../config/Encrypt';
import proposalGateway from '../../../../config/service';
import serviceEndpoints from '../../../../config/serviceEndpoints';

export const logout = createAction('logout');
export const resetmsg = createAction('resetmsg');

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await proposalGateway.post(serviceEndpoints.login, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const googleIn = createAsyncThunk('auth/google', async (access_token, { rejectWithValue }) => {
    try {
        const res = await proposalGateway.post(serviceEndpoints.google, { accessToken: access_token });

        const { success, message } = res.data;
        if (success) {
            return res?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
const slice = createSlice({
    name: 'signIn',
    initialState: {
        resMsg: '',
        isLoading: false,
        isLoggedIn: false,
        isGoogleLoading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isLoggedIn = false;
            })
            .addCase(signIn.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload;
                state.isLoggedIn = false;
            })
            .addCase(signIn.fulfilled, (state, { payload }) => {
                const {
                    user: { accessToken, email, firstName, Designation, phoneNumber, photo, refreshToken },
                    success,
                    message,
                } = payload;
                if (success === true) {
                    state.isLoggedIn = true;
                    localStorage.setItem('refreshToken', refreshToken);
                    const encryptedToken = encryptAccessToken(accessToken);
                    localStorage.setItem('accessToken', encryptedToken);
                    // localStorage.setItem(
                    //     'userDetails',
                    //     JSON.stringify({ email, firstName, designation: Designation?.name, phoneNumber, photo })
                    // );
                }
                state.isLoading = false;
                state.resMsg = message;
                state.isLoggedIn = success;
            })

            .addCase(googleIn.pending, (state) => {
                state.isGoogleLoading = true;
                state.resMsg = '';
                state.isLoggedIn = false;
            })
            .addCase(googleIn.rejected, (state, { payload }) => {
                state.isGoogleLoading = false;
                state.resMsg = payload;
                state.isLoggedIn = false;
            })
            .addCase(googleIn.fulfilled, (state, { payload }) => {
                const {
                    user: { accessToken, email, firstName, Designation, phoneNumber, photo, refreshToken },
                    success,
                    message,
                } = payload;

                if (success === true) {
                    state.isGoogleLoading = false;
                    state.isLoggedIn = true;
                    const encryptedToken = encryptAccessToken(accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('accessToken', encryptedToken);
                    if (payload?.type === 'Employee') {
                        localStorage.setItem(
                            'userDetails',
                            JSON.stringify({ email, firstName, designation: Designation?.name, phoneNumber, photo })
                        );
                    }
                }
                state.isGoogleLoading = false;
                state.resMsg = message;
                state.isLoggedIn = success;
            })

            .addCase('resetmsg', (state) => {
                state.isLoading = current(state).isLoading;
                state.isLoggedIn = current(state).isLoggedIn;
                state.resMsg = '';
            })
            .addCase('logout', (state) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.resMsg = '';
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
            });
    },
});

export default slice.reducer;
