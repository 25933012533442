import React from 'react';
import { Navigate } from 'react-router-dom';

import '../assets/scss/common.scss';
import '../assets/scss/sidenav.scss';
import '../assets/scss/dark-theme.scss';
import '../assets/scss/forms.scss';
import '../assets/scss/tablestyles.scss';
import '../assets/scss/modal.scss';
import '../assets/scss/listings.scss';
import '../assets/scss/dashboard.scss';
import '../assets/scss/portfolio.scss';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/custom.css';
import '../assets/scss/modal-full-width.scss';

import 'react-tooltip/dist/react-tooltip.css';

import PrivateLayout from './layouts/PrivateLayout';

function PrivateRoute({ children, isLoggedIn }) {
    return isLoggedIn ? <PrivateLayout>{children}</PrivateLayout> : <Navigate to="/" />;
}

export default PrivateRoute;
