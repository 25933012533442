/* eslint-disable */
import axios from 'axios';
import API_ROUTE from './serviceConstants';
import { encryptAccessToken, decryptAccessToken } from './Encrypt';
import { isTokenExpired } from '../middlewares/AuthCheck';
import serviceEndpoints from './serviceEndpoints';
import { Notifications, errorNotify } from '../utils';

const proposalGateway = axios.create({
    baseURL: API_ROUTE,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 20000,
});

// proposalGateway.interceptors.response.use(
//     (response) => response,
//     async ({ response }) => {
//         if (response?.data?.statusCode === 400) {
//             if (!errorNotify.includes(response.data?.errorName)) {
//                 if (Array.isArray(response?.data?.message)) {
//                     response?.data?.message.forEach((item) => Notifications(item, 'error'));
//                 } else Notifications(response?.data?.message, 'error');
//             }
//             return response;
//         }
//         if (response?.data?.statusCode === 401) {
//             const refreshToken = localStorage.getItem('refreshToken');
//             const accessToken = localStorage.getItem('accessToken');
//             const decryptedToken = decryptAccessToken(accessToken);
//             if (refreshToken && isTokenExpired(decryptedToken)) {
//                 console.log('refreshToken', refreshToken);
//                 const axiosService = axios.create({
//                     baseURL: API_ROUTE,
//                     headers: {
//                         Authorization: `Bearer ${refreshToken}`,
//                     },
//                     timeout: 20000,
//                 });
//                 const { data } = await axiosService.post(serviceEndpoints.refresh);
//                 console.log('oldAccessToken', data.accessToken, accessToken, data.accessToken === accessToken);
//                 const encryptedToken = encryptAccessToken(data.accessToken);
//                 localStorage.setItem('accessToken', encryptedToken);
//                 console.log('newAccessToken', data.accessToken, accessToken, data.accessToken === accessToken);
//             }
//         }
//         return response;
//     }
// );

proposalGateway.interceptors.response.use(
    (response) => response,
    async ({ response, config }) => {
        if (response?.data?.statusCode === 400) {
            if (!errorNotify.includes(response.data?.errorName)) {
                if (Array.isArray(response?.data?.message)) {
                    response?.data?.message.forEach((item) => Notifications(item, 'error'));
                } else Notifications(response?.data?.message, 'error');
            }
            return response;
        }
        if (response?.data?.statusCode === 401) {
            const refreshToken = localStorage.getItem('refreshToken');
            const isRefreshTokenExpired = isTokenExpired(refreshToken);
            if (!isRefreshTokenExpired) {
                const accessToken = localStorage.getItem('accessToken');
                const decryptedToken = decryptAccessToken(accessToken);
                if (refreshToken && isTokenExpired(decryptedToken)) {
                    localStorage.removeItem('accessToken');
                    const axiosService = axios.create({
                        baseURL: API_ROUTE,
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                        timeout: 20000,
                    });
                    const { data } = await axiosService.post(serviceEndpoints.refresh);
                    const encryptedToken = encryptAccessToken(data.accessToken);
                    localStorage.setItem('accessToken', encryptedToken);
                    const dToken = decryptAccessToken(encryptedToken);
                    config.headers.Authorization = `Bearer ${dToken}`;
                    return proposalGateway(config);
                }
            } else {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
            }
        }
        return response;
    }
);

proposalGateway.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
        const decryptedToken = decryptAccessToken(token);
        config.headers = { ...config.headers, Authorization: `Bearer ${decryptedToken}` };
    }

    return config;
});

export default proposalGateway;
